import AuthContext from "@/store/contexts/AuthContext";
import React, { useContext, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "sonner";

const RoleProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { organization } = useContext(AuthContext);
  const loaded = useRef(false);

  useEffect(() => {
    if (organization?.role === "event_manager" || organization?.role === "") {
      if (loaded.current) return;
      loaded.current = true;
      toast.error("You do not have permission to access this page");
    }
  }, [organization]);

  if (organization?.role === "event_manager" || organization?.role === "") {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default RoleProtectedRoute;
