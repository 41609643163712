import { Skeleton } from "@/components/ui/skeleton";

const NavSkeleton = ({ width = "w-full" }: { width?: string }) => {
  return (
    <div className="flex flex-row gap-2 w-full">
      <Skeleton className="h-8 w-8 rounded-md" />
      <Skeleton className={`h-8 ${width}`} />
    </div>
  );
};

const EventNavbarSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 w-full p-4">
      <Skeleton className="h-36 w-full" />
      <Skeleton className="h-8 w-full" />
      <div className="flex flex-col gap-4 w-full px-2">
        <NavSkeleton width="w-24" />
        <NavSkeleton width="w-32" />
        <NavSkeleton width="w-36" />
        <NavSkeleton width="w-24" />
        <NavSkeleton width="w-32" />
      </div>
    </div>
  );
};

export default EventNavbarSkeleton;
