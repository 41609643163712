import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import DashboardContext from "@/store/contexts/DashboardContext";
import { useNavigate } from "react-router-dom";
import useUnsavedChanges from "@/hooks/use-unsaved-changes";
import { LoaderCircle } from "lucide-react";
import { toast } from "sonner";

const NavigationContent = ({
  children,
  navbar,
  initialData,
  setData,
  save,
  loading,
}: {
  children: React.ReactNode;
  navbar: React.ReactNode;
  initialData: any;
  setData: (data: any) => void;
  save: () => Promise<any>;
  loading: boolean;
}) => {
  const initial = useRef(initialData);
  const hasChanges =
    JSON.stringify(initial.current) !== JSON.stringify(initialData);
  const { openConfirm } = useContext(DashboardContext);
  const navigate = useNavigate();
  const [_reload, setReload] = useState(false);

  const handleSave = async () => {
    await save();
    initial.current = initialData;
  };

  useEffect(() => {
    if (initial.current === null && initialData !== null) {
      initial.current = initialData;
      setReload((prev) => !prev);
    }
  }, [initialData]);

  const handleLeave = (nextLocation: string) => {
    openConfirm(
      "Quitter sans sauvegarder ?",
      "Vous avez des modifications non enregistrées. Si vous quittez maintenant, vos changements seront perdus.",
      {
        label: "Sauvegarder et quitter",
        onClick: async () => {
          await handleSave();
          navigate(nextLocation, { state: { force: true } });
        },
        variant: "default",
      },
      {
        label: "Quitter",
        onClick: () => {
          navigate(nextLocation, { state: { force: true } });
        },
        variant: "outline",
      },
      ""
    );
  };

  useUnsavedChanges(
    hasChanges,
    loading
      ? () => {
          toast.error("Sauvegarde en cours");
        }
      : handleLeave
  );

  return (
    <div className="w-full md:pl-[270px]">
      <div className="hidden md:flex w-[270px] bg-white h-screen fixed top-0 left-0 pt-[60px]">
        <div className="w-full p-4">{navbar}</div>
      </div>
      <div className="flex w-full justify-center">
        <div className="w-full p-4 w-full max-w-[1400px] pb-[80px]">
          {children}
        </div>
      </div>
      {hasChanges && (
        <div className="flex items-center justify-end fixed bottom-[60px] md:bottom-0 right-0 gap-4 w-full pl-[270px] px-4 h-[60px] bg-white border-t">
          <Button
            disabled={loading}
            variant={"outline"}
            onClick={() => {
              setData(initial.current);
            }}
          >
            Annuler
          </Button>
          <Button onClick={handleSave} disabled={loading}>
            {loading && <LoaderCircle className="w-4 h-4 animate-spin" />}
            Sauvegarder
          </Button>
        </div>
      )}
    </div>
  );
};

export default NavigationContent;
