import { useContext, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import AuthContext from "@/store/contexts/AuthContext";
import { ChevronDown, LogOut, Plus } from "lucide-react";
import Divider from "./Divider";
import LanguageSelector from "./LanguageSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { generate_image_path } from "@/utils/images";
import { useTranslation } from "react-i18next";

const ProfilePopover = () => {
  const [open, setOpen] = useState(false);
  const { user, organization, logout, selectOrganization, createOrganization } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button className="flex gap-8" variant="ghost">
          <span className="max-w-[128px] truncate">{organization?.title}</span>{" "}
          <ChevronDown />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="end"
        className="flex flex-col items-center w-[260px] p-1"
      >
        <div className="flex flex-col items-center w-full gap-1 max-h-[200px] overflow-y-scroll thin-navbar">
          {user.organizations.map((org) => (
            <div
              onClick={() => {
                selectOrganization(org);
                if (location.pathname.startsWith("/event/")) {
                  navigate(`/`);
                }
                setOpen(false);
              }}
              className={`flex flex-row justify-start gap-2 items-center w-full p-2 cursor-pointer hover:bg-accent rounded-md ${
                organization?.organizer === org.organizer ? "bg-slate-100" : ""
              }`}
              key={org.organizer}
            >
              <img
                src={generate_image_path(
                  org.organizer,
                  org.main_picture,
                  "small",
                  true
                )}
                className="w-[40px] h-[40px] rounded-md object-cover"
              />
              <span className="text-sm font-medium text-secondary-foreground">
                {org.title}
              </span>
            </div>
          ))}
        </div>
        <Button
          className="w-full justify-start mt-2"
          onClick={() => createOrganization("")}
          variant={"ghost"}
        >
          <Plus className="h-4 w-4 mr-2" />
          {t("navigation.createOrganization")}
        </Button>
        <Divider className="w-[90%]" />
        <p className="text-sm font-medium text-muted-foreground/80 px-4 py-2 w-full">
          {user.email}
        </p>
        <Button
          className="w-full justify-start"
          onClick={() => {
            setOpen(false);
            navigate("/profile");
          }}
          variant={"ghost"}
        >
          {t("navigation.accountSettings")}
        </Button>
        {user.is_admin && (
          <Button
            className="w-full justify-start"
            onClick={() => {
              setOpen(false);
              navigate("/admin");
            }}
            variant={"ghost"}
          >
            {t("navigation.adminInterface")}
          </Button>
        )}
        <LanguageSelector fullWidth />
        <Divider className="w-[90%]" />
        <Button
          className="w-full justify-start gap-2 text-red-600 hover:text-red-500"
          variant="ghost"
          onClick={logout}
        >
          <LogOut className="h-4 w-4 mr-2" />
          {t("navigation.logout")}
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default ProfilePopover;
