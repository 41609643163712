const Divider = ({
  className,
  vertical = false,
}: {
  className?: string;
  vertical?: boolean;
}) => {
  if (vertical)
    return <div className={`h-full w-[2px] bg-slate-100 my-2 ${className}`} />;
  return <div className={`w-full h-[2px] bg-slate-100 my-2 ${className}`} />;
};

export default Divider;
