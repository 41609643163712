import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const PTooltip = ({
  children,
  tooltipContent,
  side = "top",
}: {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
}) => {
  return (
    <Tooltip>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent side={side} className="flex flex-col text-sm">
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
};

export default PTooltip;
