import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "../ui/button";
import DashboardContext from "@/store/contexts/DashboardContext";
import { useNavigate } from "react-router-dom";
import { LoaderCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

const UpdateCreatePopup = ({
  open,
  onClose,
  title,
  initialData,
  children,
  saveLabel,
  cancelLabel,
  canSave = true,
  save,
  reload,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  title: string;
  initialData: any;
  children: React.ReactNode;
  saveLabel?: string;
  cancelLabel?: string;
  canSave?: boolean;
  save: () => Promise<any>;
  reload?: any;
}) => {
  const { openConfirm } = useContext(DashboardContext);
  const navigate = useNavigate();
  const initial = useRef(initialData);
  const hasChanges =
    JSON.stringify(initial.current) !== JSON.stringify(initialData);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const saved = await save();
    if (saved) {
      initial.current = saved;
    }
    setLoading(false);
    if (saved !== null) {
      onClose(false);
    }
  };

  const handleLeave = (nextLocation?: string) => {
    openConfirm(
      t("common.messages.leaveWithoutSaving"),
      t("common.messages.leaveWithoutSavingDescription"),
      {
        label: canSave
          ? t("common.messages.leaveWithoutSavingSave")
          : t("common.messages.leaveWithoutSavingContinue"),
        onClick: async () => {
          if (canSave) {
            await handleSave();
          }
        },
        variant: "default",
      },
      {
        label: t("common.buttons.leave"),
        onClick: () => {
          if (nextLocation) {
            navigate(nextLocation, { state: { force: true } });
          }
          onClose(false);
        },
        variant: "outline",
      },
      ""
    );
  };

  useEffect(() => {
    if (initial.current === null && initialData != null) {
      initial.current = initialData;
      setRefresh(!refresh);
    }
  }, [initialData]);

  useEffect(() => {
    initial.current = initialData;
    setRefresh(!refresh);
  }, [reload]);

  return (
    <Sheet
      open={open}
      onOpenChange={(open: boolean) =>
        open || loading ? null : hasChanges ? handleLeave() : onClose(false)
      }
    >
      <SheetContent className="data-[state=closed]:duration-200 data-[state=open]:duration-300 bg-white w-full md:w-auto md:min-w-[500px] px-0">
        <SheetHeader className="px-4">
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <div className="pt-10 max-h-[calc(100vh-174px)] md:max-h-[calc(100vh-126px)] overflow-y-auto thin-navbar pb-8 px-4">
          {children}
        </div>
        <SheetFooter className="absolute bottom-0 left-0 right-0 p-4 bg-background border-t gap-2">
          <Button
            disabled={loading}
            onClick={() => (hasChanges ? handleLeave() : onClose(false))}
            variant="outline"
          >
            {cancelLabel ?? t("common.buttons.cancel")}
          </Button>
          <Button
            onClick={handleSave}
            disabled={!canSave || !hasChanges || loading}
          >
            {loading && <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />}
            {saveLabel ?? t("common.buttons.save")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default UpdateCreatePopup;
