import axios from "@/services/axios";
import { Event, EventFull, PromoCode } from "@/type/event";
import { toast } from "sonner";
import { event_date_format } from "./format";
import { refactor_promo_code } from "./refactor";

export const saveEventPart = async (event_id: number, event_data: any) => {
  const currentEventData = await axios
    .get(`/events/${event_id}`)
    .then((e) => e.data)
    .catch((_e) => null);
  if (currentEventData) {
    const updatedEvent = await axios
      .put(`/events/${event_id}`, {
        ...currentEventData,
        ...event_data,
        access_token: "",
      })
      .then((e) => e.data)
      .catch((_e) => null);
    if (updatedEvent) {
      toast.success("Événement mis à jour");
      return updatedEvent;
    }
  }
  toast.error("Échec à la mise à jour des données de l'événement");
  return null;
};

export const formatEventTitle = (title: string) => {
  return `${title.replaceAll(" ", "-")}`;
};

export const duplicateEvent = async (
  organization: string,
  event_id: number
) => {
  const event: Event | null = await axios
    .get(`/events/${event_id}`)
    .then((e) => e.data)
    .catch((_e) => null);
  const event_full: EventFull | null = await axios
    .get(`/dashboard/${organization}/events/${event_id}`)
    .then((e) => e.data)
    .catch((_e) => null);

  const members = await axios
    .get(`/events/${event_id}/accesses`)
    .then((e) => e.data)
    .catch((_e) => null);

  const promo_codes = await axios
    .get(`events/${event_id}/promo-code`)
    .then((e) => {
      return e.data.map((code: PromoCode) => refactor_promo_code(code));
    })
    .catch((_e) => null);

  if (event && event_full && members && promo_codes) {
    const newEvent = {
      organizer: event.organizer,
      title: event.title,
      small_description: event.small_description,
      description: event.description,
      main_picture: "",
      primary_color: event.primary_color,
      start_at: event.start_at,
      end_at: event.end_at,
      full_date: event_date_format({
        start_at: event.start_at,
        end_at: event.end_at,
      } as Event),
      address_label: event.address_label,
      status: "draft",
      visibility: event.visibility,
      address_latitude: event.address_latitude,
      address_longitude: event.address_longitude,
      address_name: event.address_name,
      confirmation_page_message: event.confirmation_page_message,
      email_pdf_message: event.email_pdf_message,
      email_pdf_email_response: event.email_pdf_email_response,
      meta_pixel_id: event.meta_pixel_id,
      access_token: "",
    };

    const createdEvent = await axios
      .post(`/events`, newEvent)
      .then((e) => e.data)
      .catch((_e) => null);

    if (createdEvent) {
      const new_event_id = createdEvent.id;
      const correctTickets = event_full.tickets.filter(
        (t) => t.visibility !== "deleted"
      );
      for (let index = 0; index < correctTickets.length; index++) {
        const ticket = correctTickets[index];
        const newTicket = {
          title: ticket.title,
          description: ticket.description,
          price: ticket.price,
          amount: ticket.amount,
          sell_start_at: ticket.sell_start_at,
          sell_end_at: ticket.sell_end_at,
          min_per_order: ticket.min_per_order,
          max_per_order: ticket.max_per_order,
          visibility: ticket.visibility,
          restricted: ticket.restricted,
          fees_type: ticket.fees_type,
          sort: ticket.sort,
          participation_form: "",
          ticket_type: ticket.ticket_type,
          show_remaining_count: ticket.show_remaining_count,
        };
        const created = await axios
          .post(`/events/${new_event_id}/tickets`, newTicket)
          .then((e) => e.data)
          .catch((_e) => null);

        if (!created) {
          toast.error(`Échec de la duplication du ticket "${ticket.title}".`);
        }
      }
      for (
        let index = 0;
        index < event_full.participation_forms.length;
        index++
      ) {
        const form = event_full.participation_forms[index];
        const created = await axios
          .put(`/events/${new_event_id}/participation-form/${form.form_type}`, {
            title: form.title,
            single_fill: form.single_fill,
            questions: form.questions,
            ticket_target: form.ticket_target,
            event: form.event,
            form_type: form.form_type,
          })
          .then((e) => e.data)
          .catch((_e) => null);

        if (!created) {
          toast.error(
            `Échec de la duplication du formulaire "${
              form.form_type === "before" ? "de commande" : "de ticket"
            }".`
          );
        }
      }
      for (let index = 0; index < members.length; index++) {
        const new_member = members[index];
        const member = await axios
          .post(`/events/${new_event_id}/accesses`, {
            email: new_member.email,
          })
          .then((e) => e.data)
          .catch((_e) => {
            return null;
          });

        if (member) {
          const updated = await axios
            .put(`/events/${member.event}/accesses`, {
              role: member.role,
              profile: member.profile,
            })
            .then((e) => e.data)
            .catch((_e) => {
              return null;
            });
          if (!updated) {
            toast.error(
              `Échec de la duplication de l'accès de "${new_member.email}".`
            );
          }
        } else {
          toast.error(
            `Échec de la duplication de l'accès de "${new_member.email}".`
          );
        }
      }
      const all_codes: any = {};
      for (let index = 0; index < promo_codes.length; index++) {
        const code = promo_codes[index];
        code.use_count = 0;
        code.tickets = "all";
        code.event = new_event_id;
        if (all_codes[code.code]) {
          all_codes[code.code].push(code);
        } else {
          all_codes[code.code] = [code];
        }
      }
      for (const code in all_codes) {
        const resp = await axios
          .put(
            `events/${new_event_id}/promo-code/${encodeURIComponent(
              code
            )}/${encodeURIComponent(code)}/bulk`,
            all_codes[code]
          )
          .then((e) => e.data)
          .catch((_e) => null);
        if (!resp) {
          toast.error(`Échec de la duplication du code "${code}".`);
        }
      }
      toast.success("Événement dupliqué avec succès");
      return new_event_id;
    } else {
      toast.error("Échec de la duplication de l'événement");
      return null;
    }
  }

  toast.error("Événement introuvable");
  return null;
};
