import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode } from "react";

interface SortableItemProps {
  id: string | number;
  children: (handleProps: {
    ref: (element: HTMLElement | null) => void;
    attributes: Record<string, any>;
    listeners: Record<string, any>;
  }) => ReactNode;
}

export const SortableItem = ({ id, children }: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children({
        ref: setNodeRef,
        attributes,
        listeners,
      } as any)}
    </div>
  );
};

interface SortableContainerProps<T> {
  items: T[];
  getItemId: (item: T) => string | number;
  onOrderChange: (newItems: T[]) => void;
  renderItem: (
    item: T,
    handleProps: {
      ref: (element: HTMLElement | null) => void;
      attributes: Record<string, any>;
      listeners: Record<string, any>;
    }
  ) => ReactNode;
  canRenderItem?: (item: T) => boolean;
  className?: string;
}

export function SortableContainer<T>({
  items,
  getItemId,
  onOrderChange,
  renderItem,
  canRenderItem = () => true,
  className = "",
}: SortableContainerProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => getItemId(item) === active.id);
      const newIndex = items.findIndex((item) => getItemId(item) === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      onOrderChange(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(getItemId)}
        strategy={verticalListSortingStrategy}
      >
        <div className={className}>
          {items.map((item) => {
            if (!canRenderItem(item)) return null;
            return (
              <SortableItem key={getItemId(item)} id={getItemId(item)}>
                {(handleProps) => renderItem(item, handleProps)}
              </SortableItem>
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
}
