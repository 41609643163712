import AuthContext from "@/store/contexts/AuthContext";
import { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LoaderCircle, MoveRight } from "lucide-react";
import { toast } from "sonner";

const OrganizationSelector = () => {
  const { user, organization, createOrganization } = useContext(AuthContext);
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const createNewOrganization = async () => {
    if (!title) {
      toast.error("Veuillez entrer un nom d'organisation");
      return;
    }
    setLoading(true);
    await createOrganization(title);
    setLoading(false);
  };

  if (organization && !user.newOrganization) return null;

  if (user.organizations.length === 0 || user.newOrganization) {
    return (
      <Dialog
        open={true}
        onOpenChange={(needOpen) => {
          if (!needOpen && organization) {
            createOrganization("");
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Nom de l’organisation</DialogTitle>
            <DialogDescription>
              Ce nom sera affiché comme organisateur de votre événement. Vous
              pourrez le modifier plus tard !
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Input
              title={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Nom de l’organisation"
              id="name"
              className="col-span-3"
            />
          </div>
          <DialogFooter>
            <Button
              disabled={loading}
              onClick={createNewOrganization}
              type="submit"
            >
              {loading && (
                <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
              )}
              Suivant <MoveRight />
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};

export default OrganizationSelector;
