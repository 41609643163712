import { SortableContainer } from "@/components/common/SortableContainer";
import UpdateCreatePopup from "@/components/common/UpdateCreatePopup";
import EventSave from "@/components/pages/event/EventSave";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import ColoredBadge from "@/components/ui/colored-badge";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { MultiSelect } from "@/components/ui/multi-select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import EventContext from "@/store/contexts/EventContext";
import { EventFull, Form } from "@/type/event";
import { generateRandomString } from "@/utils/common";
import {
  ChevronDown,
  ChevronUp,
  CircleChevronDown,
  Ellipsis,
  ExternalLink,
  GripVertical,
  LayoutList,
  List,
  MessageSquareQuote,
  Plus,
  SquareCheckBig,
  SquarePen,
  Text,
  Trash,
  Type,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "@/services/axios";
import { toast } from "sonner";
import DashboardContext from "@/store/contexts/DashboardContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const fieldTypIcon = (type: string, className: string = "h-4 w-4") => {
  switch (type) {
    case "text":
      return <Text className={className} />;
    case "short-text":
      return <Type className={className} />;
    case "unique-choice":
      return <List className={className} />;
    case "multiple-choice":
      return <LayoutList className={className} />;
    case "selection":
      return <CircleChevronDown className={className} />;
    case "additional-conditions":
      return <SquareCheckBig className={className} />;
    default:
      return <MessageSquareQuote className={className} />;
  }
};

interface FormQuestion {
  id: string;
  allow_target: boolean;
  title: string;
  description: string;
  mandatory: boolean;
  options: { title: string; id: string }[];
  ticket_target: any[];
  type: string;
}

const OptionItem = ({ option, onOptionChange, dragHandleProps }: any) => {
  return (
    <div className="flex flex-row items-center gap-2 justify-between bg-white border p-2 rounded-md">
      <div
        ref={dragHandleProps.ref}
        {...dragHandleProps.attributes}
        {...dragHandleProps.listeners}
        className="cursor-grab text-gray-400 hover:text-black"
      >
        <GripVertical className="h-5 w-5" />
      </div>
      <Input
        placeholder={`Label de l'option`}
        value={option.title}
        onChange={(e) => onOptionChange({ ...option, title: e.target.value })}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={() => onOptionChange(option, true)}
      >
        <Trash className="h-4 w-4 text-destructive" />
      </Button>
    </div>
  );
};

const QuestionItemForm = ({
  question,
  onChange,
  fieldTypes,
}: {
  question: FormQuestion;
  onChange: (question: FormQuestion) => void;
  fieldTypes: any;
}) => {
  const { currentEvent } = useContext(EventContext);
  const typeList = Object.keys(fieldTypes).map((key) => ({
    value: key,
    label: fieldTypes[key],
  }));

  const handleOrderChange = (newOrder: any) => {
    onChange({
      ...question,
      options: newOrder,
    });
  };

  const onOptionChange = (
    option: { title: string; id: string },
    deleteOption = false
  ) => {
    if (deleteOption) {
      return onChange({
        ...question,
        options: question.options.filter((o) => o.id !== option.id),
      });
    } else {
      onChange({
        ...question,
        options: question.options.map((o) => (o.id === option.id ? option : o)),
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Label htmlFor="title">Label du champ</Label>
        <Input
          id="title"
          placeholder={`Label du champ`}
          value={question.title}
          onChange={(e) => onChange({ ...question, title: e.target.value })}
        />
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          checked={question.mandatory}
          onCheckedChange={(checked) =>
            onChange({ ...question, mandatory: checked ? true : false })
          }
          id="mandatory"
        />
        <label
          htmlFor="mandatory"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Champ obligatoire
        </label>
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="type">Type de champ</Label>
        <Select
          value={question.type}
          onValueChange={(value) => onChange({ ...question, type: value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisir un type de champ" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Choisir un type de champ</SelectLabel>
              {typeList.map((type) => (
                <SelectItem key={type.value} value={type.value}>
                  <div className="flex !flex-row items-center gap-2">
                    {fieldTypIcon(type.value)}
                    <span>{type.label}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        {["selection", "multiple-choice", "unique-choice"].includes(
          question.type
        ) && (
          <div className="flex flex-col w-full gap-2 p-2 bg-background/20 border rounded-md">
            <span className="text-sm text-foreground-100 pb-2">Options</span>
            <SortableContainer
              items={question.options}
              getItemId={(item) => item.id}
              onOrderChange={handleOrderChange}
              canRenderItem={() => true}
              renderItem={(option, handleProps) => (
                <OptionItem
                  key={option.id}
                  option={option}
                  dragHandleProps={handleProps}
                  onOptionChange={onOptionChange}
                />
              )}
              className="flex flex-col gap-4 w-full"
            />
            <Button
              onClick={() =>
                onChange({
                  ...question,
                  options: [
                    ...question.options,
                    {
                      id: generateRandomString(16),
                      title: "",
                    },
                  ],
                })
              }
              variant="link"
              className="justify-start"
            >
              <Plus className="h-4 w-4" /> Ajouter une option
            </Button>
          </div>
        )}
      </div>

      {!["additional-conditions", "no-field"].includes(question.type) && (
        <div className="flex flex-col gap-2">
          <Label htmlFor="description">Description du champ</Label>
          <Input
            id="description"
            placeholder={`Description du champ`}
            value={question.description}
            onChange={(e) =>
              onChange({ ...question, description: e.target.value })
            }
          />
        </div>
      )}
      <div className="flex flex-col gap-2">
        <Label htmlFor="description">
          N’afficher que pour certains billets
        </Label>
        <MultiSelect
          options={
            currentEvent?.tickets
              .filter((ticket) => ticket.visibility !== "deleted")
              .map((ticket) => ({
                value: ticket.id,
                label: ticket.title,
              })) as any
          }
          placeholder="Tous les tickets si vide"
          defaultValue={question.ticket_target}
          onValueChange={(values) => {
            onChange({ ...question, ticket_target: values });
          }}
        />
      </div>
    </div>
  );
};

const QuestionItem = ({
  question,
  dragHandleProps,
  type,
  refreshForm,
  fieldTypes,
  required = false,
}: {
  question: FormQuestion;
  dragHandleProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: Record<string, any>;
    listeners: Record<string, any>;
  };
  type: string;
  refreshForm: () => Promise<void>;
  fieldTypes: Record<string, string>;
  required?: boolean;
}) => {
  const [questionData, setQuestionData] = useState(question);
  const { openConfirm } = useContext(DashboardContext);
  const { currentEvent } = useContext(EventContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const disabled = dragHandleProps ? false : true;
  const { t } = useTranslation();

  useEffect(() => {
    setQuestionData(question);
  }, [question]);

  const updateQuestion = async (
    questionData: FormQuestion,
    deleteQuestion = false
  ) => {
    const existingForm: Form[] = await axios
      .get(`events/${currentEvent?.id}/participation-form`)
      .then((e) => e.data)
      .catch((_e) => null);
    if (existingForm) {
      let matchForm: any = existingForm.find((f) => f.form_type === type);
      if (!matchForm) {
        matchForm = {
          title: "",
          single_fill: false,
          questions: [],
          ticket_target: [],
          event: currentEvent?.id || 0,
          form_type: type,
        };
      } else {
        matchForm.questions = JSON.parse(matchForm.questions);
        matchForm.ticket_target =
          matchForm.ticket_target === "all"
            ? []
            : JSON.parse(matchForm.ticket_target);
        delete matchForm.id;
      }
      matchForm.questions = matchForm.questions
        .filter((q: any) => !deleteQuestion || q.id !== questionData.id)
        .map((question: any) => {
          if (question.id !== questionData.id) {
            return question;
          }
          return questionData;
        });

      const ret = await axios
        .put(`/events/${currentEvent?.id}/participation-form/${type}`, {
          title: matchForm.title,
          single_fill: !matchForm.questions.some((q: any) => q.mandatory),
          questions: JSON.stringify(matchForm.questions),
          ticket_target: JSON.stringify("all"),
          event: matchForm.event,
          form_type: type,
        })
        .then((e) => e.data)
        .catch((_e) => null);
      await refreshForm();
      if (ret) {
        toast.success("Formulaire mis à jour");
      }
    }
  };

  const handlePreDelete = async () => {
    openConfirm(
      "Delete question",
      "Are you sure you want to delete this question?",
      {
        label: "Delete",
        onClick: async () => {
          await updateQuestion(questionData, true);
        },
        variant: "destructive",
      },
      {
        label: "cancel",
        onClick: () => {},
        variant: "outline",
      },
      ""
    );
  };

  return (
    <div
      className={`flex flex-row items-center justify-between gap-4 border-b border-border/80 py-4 ${
        disabled ? "opacity-60" : ""
      }`}
    >
      <div className="flex flex-row items-center gap-4 w-full">
        {dragHandleProps ? (
          <div
            ref={dragHandleProps.ref}
            {...dragHandleProps.attributes}
            {...dragHandleProps.listeners}
            className="cursor-grab text-gray-400 hover:text-black"
          >
            <GripVertical className="h-5 w-5" />
          </div>
        ) : (
          <div className="text-gray-400">
            <GripVertical className="h-5 w-5" />
          </div>
        )}
        <div
          onClick={() => !disabled && setOpenUpdate(true)}
          className={`flex flex-col gap-1 group w-full cursor-pointer`}
        >
          <div
            className={`text-sm font-semibold ${
              disabled ? "" : "group-hover:underline"
            }`}
          >
            {question.title}
          </div>
          <div
            className={`flex flex-row items-center gap-2 text-sm text-gray-500 ${
              disabled ? "" : "group-hover:underline"
            }`}
          >
            {fieldTypIcon(question.type, "w-3 h-3")}
            {fieldTypes[question.type as keyof typeof fieldTypes]}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-2">
        {disabled && (
          <ColoredBadge color="bg-[#F4F4F5]">Par défaut</ColoredBadge>
        )}
        <div
          onClick={() => {
            if (!disabled) {
              const newQuestion = {
                ...question,
                mandatory: !question.mandatory,
              };
              setQuestionData(newQuestion);
              updateQuestion(newQuestion);
            }
          }}
        >
          <ColoredBadge
            className={disabled ? "" : "cursor-pointer"}
            color={
              question.mandatory || required ? "bg-green-200" : "bg-primary/20"
            }
          >
            {question.mandatory || required ? "Obligatoire" : "Facultatif"}
          </ColoredBadge>
        </div>

        <Popover>
          <PopoverTrigger asChild>
            <Button className="ml-6" disabled={disabled} variant={"ghost"}>
              <Ellipsis />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col w-[140px] p-1">
            <Button
              onClick={() => setOpenUpdate(true)}
              variant="ghost"
              className="w-full justify-start"
            >
              <SquarePen /> {t("common.buttons.update")}
            </Button>

            <Button
              onClick={handlePreDelete}
              variant="ghost"
              className="w-full justify-start text-red-600 hover:text-red-500"
            >
              <Trash /> {t("common.buttons.delete")}
            </Button>
          </PopoverContent>
        </Popover>
      </div>
      <UpdateCreatePopup
        initialData={questionData}
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        title={`Modifier le champ`}
        saveLabel={t("common.buttons.save")}
        canSave={questionData.title !== ""}
        save={() => updateQuestion(questionData)}
        reload={question}
      >
        <QuestionItemForm
          fieldTypes={fieldTypes}
          question={questionData}
          onChange={setQuestionData}
        />
      </UpdateCreatePopup>
    </div>
  );
};

const FormQuestionCreationPopup = ({
  type,
  refreshForm,
  fieldTypes,
}: {
  type: string;
  refreshForm: () => Promise<void>;
  fieldTypes: Record<string, string>;
}) => {
  const [openCreate, setOpenCreate] = useState(false);
  const { currentEvent } = useContext(EventContext);
  const [questionData, setQuestionData] = useState<FormQuestion>({
    id: generateRandomString(16),
    allow_target: false,
    title: "",
    description: "",
    mandatory: false,
    options: [],
    ticket_target: [],
    type: "short-text",
  });
  const { t } = useTranslation();

  const createQuestion = async () => {
    const existingForm: Form[] = await axios
      .get(`events/${currentEvent?.id}/participation-form`)
      .then((e) => e.data)
      .catch((_e) => null);
    if (existingForm) {
      let matchForm: any = existingForm.find((f) => f.form_type === type);
      if (!matchForm) {
        matchForm = {
          title: "",
          single_fill: false,
          questions: [],
          ticket_target: [],
          event: currentEvent?.id || 0,
          form_type: type,
        };
      } else {
        matchForm.questions = JSON.parse(matchForm.questions);
        matchForm.ticket_target =
          matchForm.ticket_target === "all"
            ? []
            : JSON.parse(matchForm.ticket_target);
        delete matchForm.id;
      }
      matchForm.questions = [...matchForm.questions, questionData];

      const ret = await axios
        .put(`/events/${currentEvent?.id}/participation-form/${type}`, {
          title: matchForm.title,
          single_fill: !matchForm.questions.some((q: any) => q.mandatory),
          questions: JSON.stringify(matchForm.questions),
          ticket_target: JSON.stringify("all"),
          event: matchForm.event,
          form_type: type,
        })
        .then((e) => e.data)
        .catch((_e) => null);
      await refreshForm();
      if (ret) {
        toast.success("Formulaire mis à jour");
      }
      setQuestionData({
        id: generateRandomString(16),
        allow_target: false,
        title: "",
        description: "",
        mandatory: false,
        options: [],
        ticket_target: [],
        type: "short-text",
      });
    }
  };

  return (
    <>
      <Button onClick={() => setOpenCreate(true)}>
        <Plus className="h-4 w-4" /> Ajouter un champ
      </Button>
      <UpdateCreatePopup
        initialData={questionData}
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        title={`Ajouter un champ`}
        saveLabel={t("common.buttons.add")}
        canSave={questionData.title !== "" && questionData.type !== ""}
        save={createQuestion}
        reload={null}
      >
        <QuestionItemForm
          fieldTypes={fieldTypes}
          question={questionData}
          onChange={setQuestionData}
        />
      </UpdateCreatePopup>
    </>
  );
};

const EventFormBlock = ({
  title,
  description,
  type,
  form,
  order,
  setOrder,
  refreshForm,
}: {
  title: string;
  description: string;
  type: string;
  form: Form | undefined;
  order: { id: string; order: number }[];
  setOrder: (order: any) => void;
  refreshForm: () => Promise<void>;
}) => {
  const [questions, setQuestions] = useState<FormQuestion[]>([]);
  const [open, setOpen] = useState(true);

  const fieldTypes = {
    "short-text": "Texte court",
    text: "Champ texte",
    "unique-choice": "Choix unique",
    "multiple-choice": "Choix multiple",
    selection: "Sélection",
    "additional-conditions": "Condition supplémentaire",
    "no-field": "Texte sans champ",
  };

  useEffect(() => {
    const updatedQuestions: FormQuestion[] = [
      {
        id: "default",
        allow_target: false,
        title: "Nom, prénom, email",
        description: "",
        mandatory: false,
        options: [],
        ticket_target: [],
        type: "short-text",
      } as FormQuestion,
    ];

    if (form) {
      const orderableQuestions: FormQuestion[] = JSON.parse(form.questions);
      updatedQuestions.push(...orderableQuestions);
    }

    setQuestions(updatedQuestions);
  }, [form]);

  const handleOrderChange = (newOrder: any) => {
    setOrder(
      newOrder.map((item: any, index: number) => ({
        ...item,
        order: index,
      }))
    );
  };

  if (!questions.length) return null;

  return (
    <Card>
      <div
        className={`flex flex-row items-center justify-between ${
          open ? "border-b-2 border-b-primary/10" : ""
        } p-4`}
      >
        <div className="flex flex-row items-center gap-4">
          <Button
            onClick={() => setOpen(!open)}
            size={"icon"}
            variant={"ghost"}
            className="bg-primary/10"
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </Button>
          <div className="flex flex-col gap-1 max-w-[calc(100%-80px)]">
            <h2 className="text-md font-semibold">{title}</h2>
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>
        </div>
        <FormQuestionCreationPopup
          type={type}
          refreshForm={refreshForm}
          fieldTypes={fieldTypes}
        />
      </div>
      {open && (
        <div className="flex flex-col gap-2 p-4">
          <QuestionItem
            fieldTypes={fieldTypes}
            type={type}
            question={questions[0]}
            refreshForm={refreshForm}
            required={form?.single_fill === false || type === "before"}
          />
          <SortableContainer
            items={order}
            getItemId={(item) => item.id}
            onOrderChange={handleOrderChange}
            canRenderItem={(order) =>
              questions.find((question) => question.id === order.id) !==
              undefined
            }
            renderItem={(order, handleProps) => (
              <QuestionItem
                fieldTypes={fieldTypes}
                key={order.id}
                type={type}
                question={
                  questions.find((question) => question.id === order.id)!
                }
                refreshForm={refreshForm}
                dragHandleProps={handleProps}
              />
            )}
            className="flex flex-col gap-4 w-full"
          />
        </div>
      )}
    </Card>
  );
};

const generateOrder = (events: EventFull) => {
  const order: any = {
    order: [],
    tickets: [],
  };

  const order_form = events.participation_forms.find(
    (form) => form.form_type === "before"
  );

  if (order_form) {
    const questions: FormQuestion[] = JSON.parse(order_form.questions);
    order.order = questions.map((question, index) => ({
      id: question.id,
      order: index,
    }));
  }

  const ticket_form = events.participation_forms.find(
    (form) => form.form_type === "before-tickets"
  );

  if (ticket_form) {
    const questions: FormQuestion[] = JSON.parse(ticket_form.questions);
    order.tickets = questions.map((question, index) => ({
      id: question.id,
      order: index,
    }));
  }

  return order;
};

const EventForm = () => {
  const { currentEvent, setCurrentEvent } = useContext(EventContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [order, setOrder] = useState(generateOrder(currentEvent!));

  const handleSave = async () => {
    setLoading(true);

    const existingForm: Form[] = await axios
      .get(`events/${currentEvent?.id}/participation-form`)
      .then((e) => e.data)
      .catch((_e) => null);

    if (existingForm) {
      for (let index = 0; index < existingForm.length; index++) {
        const form = existingForm[index];
        if (
          form.form_type !== "before" &&
          form.form_type !== "before-tickets"
        ) {
          continue;
        }
        const formOrder =
          form.form_type === "before" ? order.order : order.tickets;
        formOrder.sort((a: any, b: any) => a.order - b.order);

        const formQuestions: FormQuestion[] = JSON.parse(form.questions);
        const formQuestionsOrdered: FormQuestion[] = [];

        formOrder.forEach((order: any) => {
          const question = formQuestions.find((q) => q.id === order.id);
          if (question) {
            formQuestionsOrdered.push(question);
          }
        });

        await axios
          .put(
            `/events/${currentEvent?.id}/participation-form/${form.form_type}`,
            {
              title: form.title,
              single_fill: form.single_fill,
              questions: JSON.stringify(formQuestionsOrdered),
              ticket_target: JSON.stringify("all"),
              event: form.event,
              form_type: form.form_type,
            }
          )
          .then((e) => e.data)
          .catch((_e) => null);
      }
      await refreshForm();
    }

    setLoading(false);
  };

  const refreshForm = async () => {
    const existingForm: Form[] = await axios
      .get(`events/${currentEvent?.id}/participation-form`)
      .then((e) => e.data)
      .catch((_e) => null);

    const newEvent: EventFull = {
      ...currentEvent,
      participation_forms: existingForm,
    } as any;
    setCurrentEvent(newEvent);
    setOrder(generateOrder(newEvent!));
    setRefresh((ref) => ref + 1);
  };

  return (
    <EventSave
      loading={loading}
      save={handleSave}
      initialData={order}
      setData={() => {}}
      reset={refresh}
    >
      <div className="flex flex-col gap-4 max-w-[800px]">
        <div className="flex flex-row items-center justify-between w-full">
          <h1 className="text-3xl font-semibold font-anek">
            Formulaires de commande
          </h1>
        </div>
        <Card className="flex flex-col bg-background border p-4 text-sm text-muted-foreground gap-2">
          <p>
            Collectez les informations dont vous avez besoin en créant un ou
            plusieurs champs qui apparaîtront lors de la commande.{" "}
            <Link
              className="inline-flex items-center gap-2 text-primary hover:underline"
              to="https://pro.passpass.be/docs/"
            >
              En savoir plus <ExternalLink className="w-3 h-3" />
            </Link>
          </p>
          <p>
            Exportez les données des formulaires depuis les actions de la{" "}
            <Link
              className="text-primary hover:underline"
              to={`/event/${currentEvent?.id}/participants`}
            >
              vue participants
            </Link>
            .
          </p>
        </Card>
        <EventFormBlock
          title="Champs de commande"
          description="Ces informations ne seront demandées qu’une seule fois par commande. Idéal pour recueillir des données de contact, de facturation ou autres..."
          type="before"
          form={currentEvent?.participation_forms.find(
            (form: any) => form.form_type === "before"
          )}
          order={order.order}
          setOrder={(o: any) => setOrder({ ...order, order: o })}
          refreshForm={refreshForm}
        />
        <EventFormBlock
          title="Champs participants"
          description="Ces informations seront demandées pour chaque ticket de la commande, afin d’identifier chaque participant individuellement."
          type="before-tickets"
          form={currentEvent?.participation_forms.find(
            (form: any) => form.form_type === "before-tickets"
          )}
          order={order.tickets}
          setOrder={(o: any) => setOrder({ ...order, tickets: o })}
          refreshForm={refreshForm}
        />
      </div>
    </EventSave>
  );
};

export default EventForm;
