import { Book, CalendarFold, Landmark, Settings } from "lucide-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import ProfilePopover from "../common/ProfilePopover";
import AuthContext from "@/store/contexts/AuthContext";
import HelpPopover from "../common/HelpPopover";

const NavbarItem = ({
  href,
  title,
  icon,
  startsWith = false,
}: {
  href: string;
  title: string;
  icon: React.ReactNode;
  startsWith?: boolean;
}) => {
  const location = useLocation();
  const isActive = startsWith
    ? location.pathname.startsWith(href)
    : location.pathname === href;

  return (
    <li>
      <Link
        className={`flex flex-row items-center gap-2 ${
          isActive ? "text-primary" : ""
        }`}
        to={href}
      >
        {React.cloneElement(icon as React.ReactElement, {
          className: `h-4 w-4 ${isActive ? "text-primary" : ""}`,
        })}
        <span className="text-sm">{title}</span>
      </Link>
    </li>
  );
};

const Navbar = () => {
  const { t } = useTranslation();
  const { organization } = useContext(AuthContext);

  return (
    <nav
      id="top-navbar"
      className="flex w-full h-[60px] flex-row items-center justify-between bg-white border-b px-4 sm:px-8 fixed top-0 z-50"
    >
      <ul className="hidden md:flex flex-row items-center gap-8">
        <NavbarItem
          href="/"
          title={t("navigation.events")}
          icon={<CalendarFold />}
        />

        {organization?.role !== "event_manager" &&
          organization?.role !== "" && (
            <>
              <NavbarItem
                href="/organization"
                title={t("navigation.organizer")}
                icon={<Book />}
              />
              <NavbarItem
                href="/finance"
                title={t("navigation.finances")}
                icon={<Landmark />}
              />
              <NavbarItem
                startsWith={true}
                href="/settings"
                title={t("navigation.settings")}
                icon={<Settings />}
              />
            </>
          )}
      </ul>
      <div className="w-full md:w-auto flex flex-row items-center justify-between md:justify-end gap-4">
        <HelpPopover label={t("navigation.help")} variant="ghost" />
        <ProfilePopover />
      </div>
    </nav>
  );
};

export default Navbar;
