import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "../ui/input";
import { LoaderCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

export type ConfirmFunction = {
  onClick: () => Promise<void> | void;
  variant: "default" | "secondary" | "outline" | "destructive";
  label: string;
};

export const defaultConfirmFunction: ConfirmFunction = {
  onClick: () => {},
  variant: "default",
  label: "",
};

const ConfirmModal = ({ openConfirmRef }: { openConfirmRef: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [validation, setValidation] = useState("");
  const [confirmation, setConfirmation] = useState({
    open: false,
    title: "",
    description: "",
    confirm: defaultConfirmFunction,
    dismiss: defaultConfirmFunction,
    confirmationText: "",
  });

  const openConfirm = (
    title: string,
    description: string,
    confirm: ConfirmFunction,
    dismiss: ConfirmFunction,
    confirmationText: string
  ) => {
    setValidation("");
    setConfirmation({
      open: true,
      title: title,
      description: description,
      confirm: confirm,
      dismiss: dismiss,
      confirmationText: confirmationText,
    });
  };

  useEffect(() => {
    openConfirmRef.current = openConfirm;
  }, []);

  return (
    <Dialog
      open={confirmation.open}
      onOpenChange={(e: any) => setConfirmation({ ...confirmation, open: e })}
    >
      <DialogContent className="sm:max-w-[425px] sm:min-w-[525px]">
        <DialogHeader>
          <DialogTitle>{confirmation.title}</DialogTitle>
          <DialogDescription>{confirmation.description}</DialogDescription>
        </DialogHeader>
        {confirmation.confirmationText !== "" && (
          <div className="grid gap-4">
            <span className="text-sm text-muted-foreground">
              {t("common.messages.confirmToProceedDangerousAction")}{" "}
              <span className="text-nowrap">
                «<i>{confirmation.confirmationText}</i>»
              </span>
            </span>
            <Input
              placeholder={confirmation.confirmationText}
              value={validation}
              onChange={(e) => setValidation(e.target.value)}
            />
          </div>
        )}
        <DialogFooter>
          <Button
            variant={confirmation.dismiss.variant as any}
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await confirmation.dismiss.onClick();
              setConfirmation({ ...confirmation, open: false });
              setIsLoading(false);
            }}
          >
            {confirmation.dismiss.label}
          </Button>
          <Button
            type="submit"
            disabled={
              (validation !== confirmation.confirmationText &&
                confirmation.confirmationText !== "") ||
              isLoading
            }
            variant={confirmation.confirm.variant as any}
            onClick={async () => {
              setIsLoading(true);
              await confirmation.confirm.onClick();
              setConfirmation({ ...confirmation, open: false });
              setIsLoading(false);
            }}
          >
            {isLoading && (
              <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
            )}
            {confirmation.confirm.label}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
