import { Skeleton } from "@/components/ui/skeleton";
import { useLocation } from "react-router-dom";

const getPageType = (pathname: string) => {
  if (pathname.endsWith("participants")) return "table";
  if (pathname.endsWith("entries")) return "table";
  if (pathname.endsWith("orders")) return "table";
  if (pathname.endsWith("team")) return "team";
  if (pathname.endsWith("order-messages")) return "order-messages";

  return "dashboard";
};

const EventSkeleton = () => {
  const location = useLocation();
  const pageType = getPageType(location.pathname);

  if (pageType === "table") {
    return (
      <div className="flex flex-col gap-4 w-full">
        <div className="flex w-full items-center justify-between">
          <Skeleton className="h-8 w-36 bg-gray-200" />
          <Skeleton className="h-8 w-64 bg-gray-200" />
        </div>
        <Skeleton className="h-64 w-full bg-gray-200" />
      </div>
    );
  } else if (pageType === "team") {
    return (
      <div className="flex flex-col gap-4 w-full max-w-[800px]">
        <div className="flex w-full items-center justify-between">
          <Skeleton className="h-8 w-36 bg-gray-200" />
          <Skeleton className="h-8 w-64 bg-gray-200" />
        </div>
      </div>
    );
  } else if (pageType === "order-messages") {
    return (
      <div className="flex flex-col gap-4 w-full max-w-[800px]">
        <div className="flex w-full items-center justify-between">
          <Skeleton className="h-8 w-36 bg-gray-200" />
        </div>
        <Skeleton className="h-64 w-full bg-gray-200" />
        <Skeleton className="h-64 w-full bg-gray-200" />
      </div>
    );
  } else if (pageType === "dashboard") {
    return (
      <div className="w-full relative">
        <div className="grid grid-cols-10 gap-4 ">
          <Skeleton className="h-64 col-span-10 xl:col-span-7 p-5 bg-gray-200" />
          <Skeleton className="h-64 col-span-10 lg:col-span-4 xl:col-span-3 p-5 bg-gray-200" />
          <Skeleton className="h-64 col-span-10 lg:col-span-6 xl:col-span-4 bg-gray-200" />
          <Skeleton className="h-64 col-span-10 xl:col-span-6 p-5 bg-gray-200" />
        </div>
      </div>
    );
  }

  return null;
};

export default EventSkeleton;
