import axios from "axios";

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/auth`,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer hello",
  },
  timeout: 6000,
});

export default instance;
