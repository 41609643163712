import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { storeData } from "../utils/store_data";

interface TokenPair {
  accessToken: string;
  refreshToken: string;
}

class AxiosTokenManager {
  private axiosInstance: AxiosInstance;
  private tokens: TokenPair | null = null;
  private maxRetry: number;
  private logoutCallback: (() => void) | null = null;
  private errorMessage: ((error: string) => void) | null = null;

  constructor(baseURL: string, maxRetry = 3) {
    this.maxRetry = maxRetry;

    this.axiosInstance = axios.create({
      baseURL,
      timeout: 6000,
    });

    this.setupInterceptors();
  }

  public setTokens(tokens: TokenPair) {
    this.tokens = tokens;
  }

  public setLogoutCallback(callback: () => void) {
    this.logoutCallback = callback;
  }

  public setErrorMessageCallback(callback: (error: string) => void) {
    this.errorMessage = callback;
  }

  private setupInterceptors() {
    // Request Interceptor
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (this.tokens?.accessToken) {
          config.headers = config.headers || {}; // Ensure headers is defined
          config.headers.Authorization = `Bearer ${this.tokens.accessToken}`;
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Response Interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & {
          retryCount?: number;
        };

        if (!error.response) {
          if (this.errorMessage) {
            this.errorMessage("common.messages.network-error");
          }
        } else if (error.response?.status === 401) {
          originalRequest.retryCount = originalRequest.retryCount ?? 0;

          if (originalRequest.retryCount < this.maxRetry) {
            originalRequest.retryCount += 1;

            try {
              const has_refresh = await this.refreshTokens();

              if (!has_refresh) {
                if (this.logoutCallback) {
                  this.logoutCallback();
                }
                return Promise.reject(error);
              }

              // Update Authorization header with the new access token
              originalRequest.headers = originalRequest.headers || {};
              if (this.tokens?.accessToken) {
                originalRequest.headers.Authorization = `Bearer ${this.tokens.accessToken}`;
              }
              return this.axiosInstance(originalRequest);
            } catch (refreshError) {
              // If refreshing tokens fails, call the logout callback
              if (this.logoutCallback) {
                this.logoutCallback();
              }
              return Promise.reject(refreshError);
            }
          } else {
            // Retry count exceeded, call the logout callback
            if (this.logoutCallback) {
              this.logoutCallback();
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private async refreshTokens(): Promise<boolean> {
    // for now no refresh token logic
    return false;
    try {
      const response = await axios.post<{
        access: string;
        refresh: string;
      }>(`${import.meta.env.VITE_API_URL}/auth/token/refresh/`, {
        refresh: this.tokens?.refreshToken,
      });

      this.tokens = {
        accessToken: response.data.access,
        refreshToken: this.tokens?.refreshToken ?? "",
      };
      storeData("access_token", response.data.access);
      return true;
    } catch (error) {
      if (error) {
      }
      if (this.logoutCallback) {
        // this.logoutCallback();
      }
      return false;
    }
  }

  public getInstance(): AxiosInstance {
    return this.axiosInstance;
  }
}

export const tokenManager = new AxiosTokenManager(
  `${import.meta.env.VITE_API_URL}/api`,
  3
);

const apiClient = tokenManager.getInstance();

export default apiClient;
