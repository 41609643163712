import { Event } from "@/type/event";

export const format_price = (price: number) => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  return new Intl.NumberFormat(currentLanguage, {
    style: "currency",
    currency: "EUR",
  }).format(price / 100);
};

export const format_number = (number: number) => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  return new Intl.NumberFormat(currentLanguage).format(number);
};

const capitalizeSentence = (sentence: string) => {
  let words = sentence.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
};

export const format_time_range = (
  startTime: Date | string,
  endTime: Date | string
) => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  if (typeof startTime === "string") {
    startTime = new Date(startTime);
  }
  if (typeof endTime === "string") {
    endTime = new Date(endTime);
  }
  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  let startTimeString = startTime
    .toLocaleTimeString(currentLanguage, options as any)
    .replace(/:\d{2}\s/, " - ");
  let endTimeString = endTime
    .toLocaleTimeString(currentLanguage, options as any)
    .replace(/:\d{2}\s/, "");

  return startTimeString + " - " + endTimeString;
};

export const format_time = (time: Date | string) => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  if (typeof time === "string") {
    time = new Date(time);
  }
  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  let timeString = time
    .toLocaleTimeString(currentLanguage, options as any)
    .replace(/:\d{2}\s/, " - ");

  return timeString;
};

export const format_date = (date: Date | string, format: string = "") => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  if (typeof date === "string") {
    date = new Date(date);
  }
  let options: any = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (format === "full_no_weekday") {
    options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  } else if (format === "full_short") {
    options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  } else if (format === "full_short_no_date") {
    options = {
      month: "short",
      day: "numeric",
    };
  } else if (format === "full_short_weekday_no_date") {
    options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };
  }

  let formated_date = date.toLocaleDateString(currentLanguage, options);

  return capitalizeSentence(formated_date);
};

function areDatesOnSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const event_date_format = (
  event: Event,
  format = "",
  no_hours = false,
  from_to_text = ["From", "to"]
) => {
  const currentLanguage = document.documentElement.lang.split("-")[0] || "en";

  const start_at =
    typeof event.start_at === "string"
      ? new Date(event.start_at)
      : event.start_at;
  const end_at =
    typeof event.end_at === "string" ? new Date(event.end_at) : event.end_at;

  if (areDatesOnSameDay(start_at, end_at)) {
    let options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (format === "small") {
      options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
    }
    let formated_date = capitalizeSentence(
      start_at.toLocaleDateString(currentLanguage, options)
    );

    if (no_hours) return formated_date;

    return `${formated_date} ${format_time_range(start_at, end_at)}`;
  } else {
    let options: any = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    let start_char = `${from_to_text[0]} `;
    let middle_char = ` ${from_to_text[1]} `;

    if (no_hours) {
      return `${start_char}${capitalizeSentence(
        start_at.toLocaleDateString(currentLanguage, options)
      )}${middle_char}${capitalizeSentence(
        end_at.toLocaleDateString(currentLanguage, options)
      )} `;
    }
    return `${start_char}${capitalizeSentence(
      start_at.toLocaleDateString(currentLanguage, options)
    )} ${format_time(start_at)}${middle_char}${capitalizeSentence(
      end_at.toLocaleDateString(currentLanguage, options)
    )} ${format_time(end_at)}`;
  }
};

export const replace_titles_description = (description: string) => {
  description = description.replaceAll("<h1>", "<span class='h1'>");
  description = description.replaceAll("</h1>", "</span>");

  description = description.replaceAll("<h2>", "<span class='h2'>");
  description = description.replaceAll("</h2>", "</span>");

  description = description.replaceAll("<h3>", "<span class='h3'>");
  description = description.replaceAll("</h3>", "</span>");

  description = description.replaceAll("<h4>", "<span class='h4'>");
  description = description.replaceAll("</h4>", "</span>");

  description = description.replaceAll("<h5>", "<span class='h5'>");
  description = description.replaceAll("</h5>", "</span>");

  description = description.replaceAll("<h6>", "<span class='h6'>");
  description = description.replaceAll("</h6>", "</span>");

  description = description.replaceAll("<a", "<a target='_blank'");
  return description;
};
