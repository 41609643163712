import NavigationContent from "@/components/common/NavigationContent";
import SimpleSelect from "@/components/common/SimpleSelect";
import TitleDescriptionCard from "@/components/common/TitleDescriptionCard";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import AuthContext from "@/store/contexts/AuthContext";
import { Info } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "@/services/axios";
import { toast } from "sonner";
import DashboardContext from "@/store/contexts/DashboardContext";
import axios_auth from "@/services/axios_auth";

const ProfileNavbar = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className="flex flex-col w-full gap-4 items-start">
      <span className="text-2xl font-semibold font-anek">
        {user.first_name} {user.last_name}
      </span>

      <Separator />
      <Button variant={"ghost"} className="bg-primary/10 justify-start w-full">
        <Info />
        Informations générales
      </Button>
    </div>
  );
};

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, profile, organization } = useContext(AuthContext);
  const { openConfirm } = useContext(DashboardContext);
  const [profileData, setProfileData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
  });
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const currentLanguage = ["en", "fr", "nl", "aa"].includes(
    i18n.language.split("-")[0]
  )
    ? i18n.language.split("-")[0]
    : "fr";

  useEffect(() => {
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  const updateProfile = async () => {
    setLoading(true);
    const updated = await axios
      .post("/me", {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        email: "",
        password: "",
      })
      .then((e) => e.data)
      .catch((_e) => null);
    if (updated) {
      profile(organization?.organizer ?? "");
      toast.success("Profile mis à jour");
    }

    setLoading(false);
  };

  const resetPassword = () => {
    openConfirm(
      "Réinitialiser le mot de passe",
      "Voulez vous recevoir un mail de récupération de mot de passe",
      {
        label: t("common.buttons.send"),
        onClick: async () => {
          const reset = await axios_auth
            .post("/request-password-reset", {
              email: user.email,
              password: "",
            })
            .then((e) => e.data)
            .catch((_e) => null);
          if (reset) {
            toast.success("Email envoyé");
          } else {
            toast.error(
              "Vous êtes connecté avec google. Vous ne pouvez pas réinitialiser votre mot de passe."
            );
          }
        },
        variant: "default",
      },
      {
        label: t("common.buttons.close"),
        onClick: () => {},
        variant: "outline",
      },
      ""
    );
  };

  const setLanguage = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <NavigationContent
      loading={loading}
      save={updateProfile}
      setData={setProfileData}
      initialData={profileData}
      navbar={<ProfileNavbar />}
    >
      <div className="flex flex-col gap-4 max-w-[800px]">
        <TitleDescriptionCard
          title={"Informations du compte"}
          description={
            "Gérez les informations personnelles et la sécurité de votre compte"
          }
        >
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col w-full gap-2">
              <span className="text-sm text-black">Email</span>
              <p className="text-muted-foreground text-xs mb-2">
                Pour modifier votre email,{" "}
                <Link
                  target="_blank"
                  className="underline text-primary"
                  to={`https://pro.passpass.be/contact-support?email=${encodeURIComponent(
                    user.email
                  )}&first_name=${encodeURIComponent(
                    user.first_name
                  )}&last_name=${encodeURIComponent(user.last_name)}`}
                >
                  contactez le support
                </Link>
              </p>
              <Input
                placeholder={"john.doe@example.com"}
                value={user.email}
                disabled={true}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <span className="text-sm text-black">Prénom</span>
              <Input
                placeholder={"John"}
                value={profileData.first_name}
                onChange={(e) =>
                  setProfileData({ ...profileData, first_name: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <span className="text-sm text-black">Nom</span>
              <Input
                placeholder={"Doe"}
                value={profileData.last_name}
                onChange={(e) =>
                  setProfileData({ ...profileData, last_name: e.target.value })
                }
              />
            </div>
            <Button
              onClick={resetPassword}
              className="w-max"
              variant={"outline"}
            >
              Réinitialiser mon mot de passe
            </Button>
          </div>
        </TitleDescriptionCard>
        {/* <TitleDescriptionCard
          title={"Langue de l’interface"}
          description={"Choisissez la langue d’affichage de votre interface."}
        >
          <SimpleSelect
            value={currentLanguage}
            onChange={setLanguage}
            options={[
              { value: "en", label: "English" },
              { value: "fr", label: "Français" },
              { value: "nl", label: "Dutch" },
            ]}
          />
        </TitleDescriptionCard> */}
      </div>
    </NavigationContent>
  );
};

export default Profile;
