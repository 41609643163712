import React from "react";
import backgroundImage from "@/assets/auth/passpass-background.jpeg";
import PassPassLogo from "@/assets/auth/passpass-icon-text-light.svg";
import { Button } from "@/components/ui/button";
import { MoveLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../common/LanguageSelector";
import HelpPopover from "../common/HelpPopover";
import { Link } from "react-router-dom";

const AuthContainer = ({
  children,
  goBack,
}: {
  children: React.ReactNode;
  goBack?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row w-full ">
      <div className="hidden md:flex flex-col w-1/2 h-screen bg-black relative">
        <img
          src={backgroundImage}
          alt="PassPass Logo"
          className="h-full object-cover opacity-40"
        />
        <div className="flex flex-col w-full h-full justify-between items-start absolute p-10 text-white">
          <Link to="/">
            <img src={PassPassLogo} />
          </Link>
          <div className="flex flex-col gap-4 max-w-[70%] text-base">
            <p>
              “Une plateforme claire, intuitive et efficace, idéale pour
              valoriser nos événements et gérer les transactions.
            </p>
            <p>Du belge pur : convivial, créatif et performant !</p>
            <p className="text-sm italic">Christophe K.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full md:w-1/2 h-screen justify-start items-start p-0 md:p-10 bg-white relative md:w-1/2">
        <div className="flex flex-row w-full justify-between md:justify-end bg-white fixed md:absolute top-0 right-0 pt-4 md:pt-10 pr-4 md:pr-10 gap-4">
          <LanguageSelector />
          <HelpPopover variant={"outline"} label={t("auth.need-help")} />
        </div>
        <div className="flex flex-col h-screen md:h-full w-full max-w-[600px] px-4 py-32 md:py-0 md:px-0 items-center overflow-y-auto">
          <div className="flex flex-col md:h-full items-start justify-center w-full max-w-[400px]">
            {goBack && (
              <Button
                className="bg-background mb-12"
                variant="link"
                onClick={goBack}
              >
                <MoveLeft />
                {t("auth.go-back")}
              </Button>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
