import SelectWithSearch from "@/components/common/SelectWithSearch";
import { Button } from "@/components/ui/button";

import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import DashboardContext from "@/store/contexts/DashboardContext";
import EventContext from "@/store/contexts/EventContext";
import { Event } from "@/type/event";
import { format_date, format_time } from "@/utils/format";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import {
  ChevronDown,
  Eye,
  Globe,
  Home,
  LoaderCircle,
  LockKeyhole,
  RocketIcon,
  ScanQrCode,
  Share2,
  ShoppingBag,
  ShoppingBasket,
  SlidersHorizontal,
  SquarePen,
  Ticket,
  UserRoundPen,
  Users,
} from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import EventNavbarSkeleton from "./EventNavbarSkeleton";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import axios from "@/services/axios";
import { toast } from "sonner";
import { generate_image_path } from "@/utils/images";
import PTooltip from "@/components/common/Tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";
import AuthContext from "@/store/contexts/AuthContext";
import { Separator } from "@/components/ui/separator";
import { copyEventLink, previewEvent } from "@/utils/common";

const EventDashboardChangeEventVisibility = ({
  open,
  setOpen,
  event,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: Event;
}) => {
  const [visibility, setVisibility] = useState(
    event.visibility ? event.visibility : "public"
  );
  const [loading, setLoading] = useState(false);
  const { fetchEvent, currentEvent } = useContext(EventContext);
  const { openConfirm } = useContext(DashboardContext);
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openTicket = useRef(false);

  const handleSave = async (draft: boolean = false) => {
    setLoading(true);

    const eventResponse = await axios
      .get(`/events/${event.id}`)
      .then((e) => e.data)
      .catch((_e) => null);
    if (eventResponse) {
      eventResponse.visibility = visibility;
      eventResponse.status = draft ? "draft" : "published";
      eventResponse.access_token = "";
      await axios.put(`/events/${event.id}`, eventResponse).catch((_e) => null);

      if (draft) {
        toast.success("Remis en brouillon");
      } else {
        toast.success(
          `Événement publié en ${visibility === "public" ? "public" : "privé"}`
        );
      }

      await fetchEvent();
    }

    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    if (searchParams.get("from-create") && !openTicket.current) {
      setSearchParams({}, { replace: true });
      openTicket.current = true;
      openConfirm(
        "Ajoutez vos billets 🎟️",
        "Votre événement est prêt ! Il ne reste plus qu’à ajouter des billets pour permettre aux participants de réserver leur place.",
        {
          label: "Créer des tickets",
          onClick: async () => {
            setOpen(false);
            navigate(`/event/${currentEvent?.id}/tickets`);
          },
          variant: "default",
        },
        {
          label: "Plus tard",
          onClick: async () => {
            setOpen(false);
          },
          variant: "outline",
        },
        ""
      );
    }
  }, [searchParams]);

  const handleFirstSave = async () => {
    if (currentEvent?.tickets.length === 0) {
      openConfirm(
        "Publier sans billets ? 🎟️",
        "Vous n’avez pas encore ajouté de billets. Les participants ne pourront pas s’inscrire à votre événement.",
        {
          label: "Créer un ticket",
          onClick: async () => {
            setOpen(false);
            navigate(`/event/${currentEvent?.id}/tickets`);
          },
          variant: "default",
        },
        {
          label: "Publier un événement sans tickets",
          onClick: async () => {
            await handleSave(false);
          },
          variant: "outline",
        },
        ""
      );
    } else {
      const havePaidTicket = currentEvent?.tickets.some(
        (ticket) => ticket.price > 0
      );
      if (havePaidTicket && !organization?.is_connected_account_valid) {
        openConfirm(
          "Connectez votre compte Stripe",
          "Liez votre compte Stripe pour vendre des billets et retirer vos paiements. Vous pouvez le faire plus tard, mais la connexion est requise pour accéder à vos fonds.",
          {
            label: "Lier Stripe",
            onClick: async () => {
              setOpen(false);
              navigate(`/finance`);
            },
            variant: "default",
          },
          {
            label: "Plus tard",
            onClick: async () => {
              await handleSave(false);
            },
            variant: "outline",
          },
          ""
        );
      } else {
        await handleSave(false);
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="bg-white">
        <DialogTitle>Visibilité de l'événement</DialogTitle>
        <DialogDescription>
          Contrôlez la visibilité de votre événement. Par défaut, il est public,
          mais vous pouvez choisir de le rendre privé
        </DialogDescription>
        <div className="w-full py-4">
          <Select
            value={visibility}
            onValueChange={(value: any) => setVisibility(value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={"Select visibility..."}>
                <span className="inline-flex items-center gap-2">
                  {visibility === "public" ? (
                    <Globe className="h-4 w-4" />
                  ) : (
                    <LockKeyhole className="h-4 w-4" />
                  )}
                  {visibility === "public" ? "Public" : "Privé"}
                </span>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Visibilité de l'événement</SelectLabel>
                {["public", "private"].map((visibility) => (
                  <SelectItem key={visibility} value={visibility}>
                    <div className="flex flex-col max-w-[400px] py-2">
                      <span className="inline-flex items-center gap-2 pb-1">
                        {visibility === "public" ? (
                          <Globe className="h-4 w-4" />
                        ) : (
                          <LockKeyhole className="h-4 w-4" />
                        )}
                        {visibility === "public" ? "Public" : "Privé"}
                      </span>
                      <span className="text-sm text-muted-foreground">
                        {visibility === "public"
                          ? "Votre événement est référencé et accessible à tous."
                          : "Uniquement accessible via le lien de l'événement, non référencé."}
                      </span>
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <DialogFooter>
          <Button
            disabled={loading}
            variant="outline"
            onClick={() =>
              event.status === "published" ? handleSave(true) : setOpen(false)
            }
          >
            {event.status === "published" ? "Remettre en brouillon" : "Annuler"}
          </Button>
          <Button disabled={loading} onClick={() => handleFirstSave()}>
            {loading ? <LoaderCircle className="h-4 w-4 animate-spin" /> : null}
            {event.status === "published"
              ? "Sauvegarder"
              : "Publier l'événement"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const EventPresentation = ({ event }: { event: Event }) => {
  const { events } = useContext(DashboardContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [openUpdateEvent, setOpenUpdateEvent] = useState(
    searchParams.get("publish") === "true"
  );

  const eventStatusDisplay = {
    draft: t("home.draft"),
    archived: t("home.archived"),
    private: t("events.navbar.private"),
    public: t("events.navbar.public"),
  };

  const day_left = Math.ceil(
    (event.start_date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  );
  return (
    <div className="flex flex-col gap-2 w-full rounded-md bg-white md:bg-[#f0f7fe] p-3 sticky top-0 z-10">
      <div className="w-full aspect-[16/9] rounded-md relative p-1.5">
        <div className="flex flex-row items-center p-1.5 gap-3 z-10 bg-white/70 backdrop-blur-sm rounded-md">
          <div className="flex items-center justify-center w-[48px] h-[48px] rounded-md bg-white">
            <span
              className={`${
                Math.abs(day_left) > 99 ? "text-xs" : "text-normal"
              } font-bold text-slate-500 truncate max-w-[60px]`}
            >
              J{day_left < 0 ? "+" : "-"}
              {Math.abs(day_left)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="inline-flex gap-2 items-center text-xs">
              {format_date(event.start_date, "full_short_weekday_no_date")},{" "}
              {format_time(event.start_date)}
            </span>
            <span className="text-xs">
              {format_date(event.end_date, "full_short_weekday_no_date")},{" "}
              {format_time(event.end_date)}
            </span>
          </div>
        </div>
        <img
          src={generate_image_path(event.id, event.main_picture, "phone")}
          className="absolute top-0 left-0 w-full h-full object-cover rounded-md -z-10"
        />
      </div>

      <SelectWithSearch
        value={event.id}
        options={events.map((event) => ({
          label: event.title === "" ? "Sans titre" : event.title,
          value: event.id,
          id: event.id,
        }))}
        onChange={(id) => navigate(`/event/${id}`)}
        placeholder={t("events.navbar.selectEvent")}
        noFoundPlaceholder={t("events.navbar.noEventFound")}
      />

      <div className="flex flex-row justify-between z-10">
        <div className="flex items-center justify-between md:justify-start w-full md:w-[calc(100%-4rem)] h-7 bg-slate-50 md:bg-white px-2 gap-2 rounded-sm">
          <div className="flex items-center gap-2">
            <div
              className={`w-2 h-2 rounded-full ${
                event.status === "published" ? "bg-green-200" : "bg-slate-200"
              }`}
            ></div>
            <span
              className={`text-xs ${
                event.status === "published"
                  ? "text-black"
                  : "text-muted-foreground"
              }`}
            >
              {event.status === "published"
                ? eventStatusDisplay[event.visibility as "private" | "public"]
                : eventStatusDisplay[event.status as "draft" | "archived"]}
            </span>
          </div>
          {event.status === "published" && (
            <span
              className="text-xs underline cursor-pointer"
              onClick={() => setOpenUpdateEvent(true)}
            >
              Modifier
            </span>
          )}
        </div>
        <PTooltip tooltipContent={<span>Prévisualiser</span>}>
          <div
            onClick={() => {
              previewEvent(event);
            }}
            className="hidden md:flex items-center justify-center w-7 h-7 bg-white rounded-sm cursor-pointer"
          >
            <Eye className="h-4 w-4" />
          </div>
        </PTooltip>
        <PTooltip tooltipContent={<span>Copier le lien</span>}>
          <div
            onClick={() => {
              // copy link in clipboard
              copyEventLink(event);
            }}
            className="hidden md:flex items-center justify-center w-7 h-7 bg-white rounded-sm cursor-pointer"
          >
            <Share2 className="h-4 w-4" />
          </div>
        </PTooltip>
      </div>
      <Button
        onClick={() => navigate(`/event/update/${event.id}`)}
        variant="outline"
        className="justify-start bg-white"
      >
        <SquarePen /> {t("events.navbar.editEvent")}
      </Button>
      {event.status !== "published" && (
        <Button
          onClick={() => setOpenUpdateEvent(true)}
          className="text-black bg-primary/40 border border-primary"
        >
          <RocketIcon /> Publier l'événement
        </Button>
      )}
      <EventDashboardChangeEventVisibility
        event={event}
        open={openUpdateEvent}
        setOpen={setOpenUpdateEvent}
      />
    </div>
  );
};

const DashboardNavbarItem = ({
  title,
  href,
  icon,
}: {
  title: string;
  href: string;
  icon: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <Button
      onClick={() => navigate(href)}
      variant="ghost"
      className={`hover:bg-primary/5 justify-start w-full ${
        isActive ? "bg-primary/10 hover:bg-primary/10" : ""
      }`}
    >
      {icon} {title}
    </Button>
  );
};

const DashboardNavbarCollapsible = ({
  title,
  icon,
  links,
}: {
  title: string;
  icon: React.ReactNode;
  links: {
    href: string;
    title: string;
    tooltip?: string;
    disabled?: boolean;
  }[];
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(links.some((link) => location.pathname == link.href));
  }, [location]);

  return (
    <Collapsible
      open={open}
      onOpenChange={setOpen}
      className="group/collapsible"
    >
      <CollapsibleTrigger asChild>
        <Button
          variant="ghost"
          className="w-full justify-between hover:bg-primary/5"
        >
          <div className="flex flex-row gap-2 items-center">
            {icon} {title}
          </div>
          <ChevronDown className={`${open ? "rotate-180" : ""} transition`} />
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <TooltipProvider delayDuration={200}>
          <div className="pl-5 pt-1">
            <div className="flex flex-col border-l pl-1 gap-1">
              {links.map((link) => {
                const isActive = location.pathname === link.href;
                if (!link.tooltip) {
                  return (
                    <Button
                      disabled={link.disabled}
                      key={link.href}
                      onClick={() => navigate(link.href)}
                      variant="ghost"
                      className={`hover:bg-primary/5 justify-start w-full ${
                        isActive ? "bg-primary/10 hover:bg-primary/10" : ""
                      }`}
                    >
                      {link.title}
                    </Button>
                  );
                }
                return (
                  <Tooltip key={link.href}>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() =>
                          link.disabled ? undefined : navigate(link.href)
                        }
                        variant="ghost"
                        className={`hover:bg-primary/5 justify-start w-full ${
                          isActive ? "bg-primary/10 hover:bg-primary/10" : ""
                        } ${
                          link.disabled
                            ? "opacity-50 bg-transparent hover:bg-transparent cursor-not-allowed"
                            : ""
                        }`}
                      >
                        {link.title}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">
                      {link.tooltip}
                    </TooltipContent>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </TooltipProvider>
      </CollapsibleContent>
    </Collapsible>
  );
};

export const EventDashboardNavbarContent = ({
  currentEvent,
}: {
  currentEvent: Event | null;
}) => {
  const { t } = useTranslation();

  if (!currentEvent) return <EventNavbarSkeleton />;

  return (
    <ScrollArea className="flex flex-col gap-4 w-full px-4 pt-4 max-h-[90svh] md:max-h-none">
      <div className="hidden md:block">
        <EventPresentation event={currentEvent} />
      </div>
      <div className="flex flex-col gap-2 w-full mt-4 pb-4 z-0">
        <DashboardNavbarItem
          title={t("events.navbar.dashboard")}
          href={`/event/${currentEvent.id}`}
          icon={<Home />}
        />
        <DashboardNavbarCollapsible
          title={t("events.navbar.ticketsTitle")}
          icon={<Ticket />}
          links={[
            {
              href: `/event/${currentEvent.id}/tickets`,
              title: t("events.navbar.tickets"),
            },
            {
              href: `/event/${currentEvent.id}/addons`,
              title: t("events.navbar.addons"),
            },
            {
              href: `/event/${currentEvent.id}/promos`,
              title: t("events.navbar.promoCodes"),
            },
          ]}
        />
        <DashboardNavbarCollapsible
          title={t("events.navbar.orderOptions")}
          icon={<ShoppingBag />}
          links={[
            {
              href: `/event/${currentEvent.id}/order-fields`,
              title: t("events.navbar.customFields"),
            },
            {
              href: `/event/${currentEvent.id}/order-messages`,
              title: t("events.navbar.customMessages"),
            },
          ]}
        />
        <DashboardNavbarItem
          title={t("events.navbar.team")}
          href={`/event/${currentEvent.id}/team`}
          icon={<UserRoundPen />}
        />
        <DashboardNavbarItem
          title={t("events.navbar.settings")}
          href={`/event/${currentEvent.id}/settings`}
          icon={<SlidersHorizontal />}
        />
        <Separator />
        <DashboardNavbarItem
          title={t("events.navbar.participants")}
          href={`/event/${currentEvent.id}/participants`}
          icon={<Users />}
        />
        <DashboardNavbarItem
          title={t("events.navbar.entries")}
          href={`/event/${currentEvent.id}/entries`}
          icon={<ScanQrCode />}
        />
        <DashboardNavbarItem
          title={t("events.navbar.orders")}
          href={`/event/${currentEvent.id}/orders`}
          icon={<ShoppingBasket />}
        />
      </div>
    </ScrollArea>
  );
};

export const EventDashboardNavbar = () => {
  const { currentEvent } = useContext(EventContext);

  return (
    <div className="hidden md:flex flex-col w-[274px] h-screen bg-white fixed top-0 left-0 pt-[60px]">
      <EventDashboardNavbarContent currentEvent={currentEvent} />
    </div>
  );
};
