import { cn } from "@/lib/utils";

const ColoredBadge = ({
  color,
  children,
  className = "",
}: {
  color: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`${cn(
        `text-black rounded-md text-xs font-semibold px-2.5 py-0.5 text-nowrap`,
        color,
        className
      )}`}
    >
      {children}
    </div>
  );
};

export default ColoredBadge;
