import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../store/contexts/AuthContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAuth: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requireAuth,
}) => {
  const { user } = useContext(AuthContext);
  const isAuthenticated = user.login_status === "authenticated";

  if (user.login_status === "pending") {
    return null;
  }

  if (location.pathname === "/auth/reset-password") {
    return <>{children}</>;
  }

  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (!requireAuth && isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
