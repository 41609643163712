import { AdminEvent, AdminOrganization } from "@/type/admin";
import {
  Entry,
  Event,
  EventFull,
  Participant,
  Payment,
  PromoCode,
  Ticket,
} from "@/type/event";
import { Member } from "@/type/user";

export const refactor_tickets = (event: EventFull, tickets: Ticket[]) => {
  const now = new Date();

  const total_bought = tickets
    .filter((ticket) => ticket.ticket_type === "ticket")
    .reduce((acc, ticket) => acc + ticket.purchased, 0);

  console.log(event);

  tickets.forEach((ticket) => {
    // Check if ticket is sold out
    if (ticket.purchased >= ticket.amount && ticket.amount != -1) {
      ticket.status = "sold_out";
      ticket.status_explanation = "sold_out_description";
      ticket.status_color = "bg-gray-200";
      return;
    }

    const sellStartAt = ticket.sell_start_at
      ? new Date(ticket.sell_start_at)
      : null;
    const sellEndAt = ticket.sell_end_at ? new Date(ticket.sell_end_at) : null;
    const eventStartAt = new Date(event.start_at);
    const evnetEndAt = new Date(event.end_at);

    // Check if event has already started
    if (now > eventStartAt && (!sellEndAt || sellEndAt <= eventStartAt)) {
      ticket.status = "not_on_sale";
      if (now > evnetEndAt) {
        ticket.status_explanation = "event_has_ended";
      } else {
        ticket.status_explanation = "event_has_already_started";
      }

      ticket.status_color = "bg-red-200";
      return;
    }

    // Check if sales haven't started yet
    if (sellStartAt && now < sellStartAt) {
      ticket.status = "not_on_sale";
      ticket.status_explanation = "sales_havent_started_yet";
      ticket.status_color = "bg-red-200";
      return;
    }

    if (ticket.visibility === "hidden") {
      ticket.status = "hidden";
      ticket.status_explanation = "hidden_description";
      ticket.status_color = "bg-red-200";
      return;
    }

    // Check if sales have ended
    if (sellEndAt && now > sellEndAt) {
      ticket.status = "not_on_sale";
      ticket.status_explanation = "sales_have_ended";
      ticket.status_color = "bg-red-200";
      return;
    }

    if (event.event_capacity !== -1 && event.event_capacity <= total_bought) {
      ticket.status = "sold_out";
      ticket.status_explanation = "sold_out_description";
      ticket.status_color = "bg-gray-200";
      return;
    }

    // If none of the above conditions are met, ticket is on sale
    ticket.status = "on_sale";
    ticket.status_explanation = "on_sale_description";
    ticket.status_color = "bg-green-200";
  });

  return tickets;
};

export const refactor_dashboard_events = (events: Event[]) => {
  events.forEach((event) => {
    event.start_date = new Date(event.start_at);
    event.end_date = new Date(event.end_at);
  });

  return events;
};

export const refactor_participants = (
  event_id: number,
  tickets: Ticket[],
  payments: Payment[],
  participants: Participant[]
) => {
  participants.forEach((participant) => {
    participant.event = event_id;
    const ticket = tickets.find((t) => t.id === participant.ticket);
    if (ticket) {
      participant.ticket_type = ticket.ticket_type;
      participant.ticket_title = ticket.title;
    }
    participant.created_date = new Date(participant.created_at);
    participant.full_name = `${participant.first_name} ${participant.last_name}`;
    participant.search = `${participant.full_name} ${participant.id}`;
    const payment = payments.find((p) => p.id === participant.payment);
    if (payment) {
      participant.access_token = payment.access_token;
    }
  });

  participants.sort((a, b) => b.id - a.id);

  return participants;
};

export const refactor_payments = (event_id: number, payments: Payment[]) => {
  payments.forEach((payment) => {
    payment.event = event_id;
    payment.search = `${payment.id}`;
    payment.full_name = `${payment.first_name} ${payment.last_name}`;
    payment.checkout_time = null;
    payment.net_amount = payment.amount - payment.fees;
    if (payment.paid_at) {
      const created = new Date(payment.created_at);
      const paid = new Date(payment.paid_at);

      payment.checkout_time = (paid.getTime() - created.getTime()) / 1000;
      payment.status = "paid";
    } else {
      const payable_until = new Date(payment.payable_until);
      if (payable_until.getTime() < new Date().getTime()) {
        payment.status = "expired";
      } else {
        payment.status = "pending";
      }
    }
  });
  payments.sort((a, b) => b.id - a.id);
  return payments;
};

export const refactor_event_full = (event: EventFull) => {
  event.start_date = new Date(event.start_at);
  event.end_date = new Date(event.end_at);

  event.participants = refactor_participants(
    event.id,
    event.tickets,
    event.payments,
    event.participants
  );
  event.payments = refactor_payments(event.id, event.payments);

  event.tickets = refactor_tickets(event, event.tickets);

  return event;
};

export const entries_refactor = (
  entries: Entry[],
  participants: Participant[]
) => {
  entries.forEach((entry) => {
    entry.search = `${entry.ticket}`;
    entry.validated_date = new Date(entry.validated_at);
    entry.is_in_str = entry.is_in ? "true" : "false";
    const participant = participants.find((t) => t.id === entry.ticket);
    if (participant) {
      entry.participant = participant;
    } else {
      entry.participant = {
        first_name: "",
        last_name: "",
      } as any;
    }
  });
  entries.sort((a, b) => b.id - a.id);
  return entries;
};

export const refactor_members = (
  members: Member[],
  is_event_member: boolean = false
) => {
  members.forEach((member) => {
    member.full_name = `${member.first_name} ${member.last_name}`;

    const roles = member.role.split("|");
    member.can_validate = false;

    if (is_event_member) {
      if (roles.includes("validator")) {
        member.can_validate = true;
      }
      if (roles.includes("team_manager")) {
        member.role = "admin";
      } else if (roles.includes("event_manager")) {
        member.role = "event_manager";
      }
    }
  });

  members.sort((a, b) => a.full_name.localeCompare(b.full_name));

  return members;
};

export const refactor_promo_code = (promo_code: PromoCode) => {
  if (promo_code.start_at) {
    promo_code.start_at = new Date(promo_code.start_at);
  }
  if (promo_code.end_at) {
    promo_code.end_at = new Date(promo_code.end_at);
  }

  return promo_code;
};

export const refactor_admin_data = (data: {
  organizations: AdminOrganization[];
  events: AdminEvent[];
}) => {
  data.organizations.forEach((organization) => {
    organization.search = `${organization.id} ${organization.title}`;
    organization.stripe_connected_status = organization.connected_account_id
      ? organization.is_connected_account_valid
        ? "valid_conected"
        : "need_validation"
      : "not_connected";
  });
  data.events.forEach((event) => {
    event.search = `${event.id} ${event.title}`;
  });
  return data;
};
