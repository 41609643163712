import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import fr from "./locales/fr.json";
// import nl from "./locales/nl.json";
// import aa from "./locales/aa.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      // nl: { translation: nl },
      // aa: { translation: aa },
    },
    fallbackLng: "fr",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
