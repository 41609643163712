import React, { ReactNode, useEffect } from "react";
import { createContext, useState } from "react";
import { getData, storeData } from "@/utils/store_data";
import axios, { tokenManager } from "@/services/axios";
import axios_auth from "@/services/axios_auth";
import {
  GOOGLE_AUTH_URL,
  GOOGLE_CLIENT_ID,
  GOOGLE_REDIRECT_URI,
} from "@/data/auth";
import { Organization, User } from "@/type/user";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import ConnexionError from "@/components/common/ConnexionError";

const default_user: User = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  is_admin: false,
  login_status: "pending",
  organizations: [],
  newOrganization: false,
};

type AuthContextType = {
  user: User;
  organization: Organization | null;
  login: (email: string, password: string) => Promise<boolean>;
  register: (email: string, code: string, password: string) => Promise<any>;
  logout: () => void;
  profile: (organization: string | null) => Promise<void>;
  selectOrganization: (organization: Organization) => void;
  createOrganization: (title: string) => Promise<void>;
  googleAuth: (code: string | undefined) => Promise<void>;
  needRegister: (email: string) => Promise<boolean>;
  validateEmail: (
    email: string,
    first_name: string,
    last_name: string
  ) => Promise<boolean>;
  fetchAndSelectOrganization: (id: string) => Promise<void>;
};

const initialAuthContextValue: AuthContextType = {
  user: { ...default_user },
  organization: null,
  login: async (_email: string, _password: string) => true,
  register: async (_email: string, _code: string, _password: string) => true,
  logout: () => {},
  profile: async () => {},
  selectOrganization: (_organization: Organization) => {},
  createOrganization: async (_title: string) => {},
  googleAuth: async () => {},
  needRegister: async (_email: string) => false,
  validateEmail: async (
    _email: string,
    _first_name: string,
    _last_name: string
  ) => false,
  fetchAndSelectOrganization: async (_id: string) => {},
};

const AuthContext = createContext(initialAuthContextValue);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState({ ...default_user });
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { t } = useTranslation();

  const login = async (email: string, password: string) => {
    const tokens = await axios_auth
      .post("/login", {
        email: email,
        password: password,
      })
      .then((e) => e.data)
      .catch((_e) => null);
    if (tokens) {
      tokenManager.setTokens({
        accessToken: tokens,
        refreshToken: tokens,
      });
      tokenManager.setLogoutCallback(logout);
      storeData("access_token", tokens);
      storeData("refresh_token", tokens);
      await profile(null);
      return true;
    }
    return false;
  };

  const fetchOrganization = async () => {
    const data = getData();
    const organizations = await axios
      .get(data.askAdmin ? "/me/organizers-admin" : "/me/organizers")
      .then((response) => response.data)
      .catch((_e) => null);
    organizations.sort((a: Organization, b: Organization) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
    return organizations;
  };

  const selectOrganization = (organization: Organization) => {
    storeData("current_organization", organization.organizer);
    setOrganization(organization);
  };

  const createOrganization = async (title: string) => {
    if (!title) {
      if (user.newOrganization)
        setUser((user) => ({ ...user, newOrganization: false }));
      else setUser((user) => ({ ...user, newOrganization: true }));
    } else {
      const created = await axios
        .post("/organizers", {
          title: title,
          description: "",
          is_private: false,
          address_label: "",
          address_latitude: 50,
          address_longitude: 50,
          main_picture: "",
          cover_picture: "",
          page_url: "",
        })
        .then((e) => e.data)
        .catch((_e) => null);
      if (created) {
        const new_organization_id = created.id;
        const organizations = await fetchOrganization();
        const new_organization = organizations?.find(
          (o: Organization) => o.organizer === new_organization_id
        );
        if (new_organization) {
          storeData("current_organization", new_organization.organizer);
          setOrganization(new_organization);
          setUser((user) => ({
            ...user,
            organizations: organizations,
            newOrganization: false,
          }));
        }
        toast.success("Organisation créée avec succès. À vous de jouer !");
      } else {
        toast.error("Ce nom est déjà pris. Veuillez en choisir un autre.");
      }
    }
  };

  const fetchAndSelectOrganization = async (id: string) => {
    const organizations = await fetchOrganization();
    const organization = organizations?.find(
      (o: Organization) => o.organizer === id
    );
    if (organization) {
      selectOrganization(organization);
    }
  };

  const needRegister = async (email: string) => {
    const need_register = await axios_auth
      .post("/need-register", {
        email: email,
        password: email,
      })
      .then((e) => e.data)
      .catch((_e) => false);
    return need_register;
  };

  const validateEmail = (
    email: string,
    first_name: string,
    last_name: string
  ) => {
    return axios_auth
      .post("/verify-email", {
        email: email,
        first_name: first_name,
        last_name: last_name,
      })
      .then((_e) => true)
      .catch((_e) => false);
  };

  const profile = async (current_organization: string | null) => {
    const profile = await axios
      .get("/me")
      .then((e) => e.data)
      .catch((_e) => null);
    if (profile) {
      storeData("user", profile);
      const organizations = await fetchOrganization();
      if (organizations) {
        if (
          current_organization &&
          organizations.find(
            (o: Organization) => o.organizer === current_organization
          )
        ) {
          setOrganization(
            organizations.find(
              (o: Organization) => o.organizer === current_organization
            )
          );
        } else if (organizations.length > 0) {
          storeData("current_organization", organizations[0].organizer);
          setOrganization(organizations[0]);
        }
        setUser((user) => ({
          ...user,
          is_admin: profile.is_admin,
          email: profile.email,
          first_name: profile.first_name,
          last_name: profile.last_name,
          auth_method: profile.auth_method,
          login_status: "authenticated",
          organizations: organizations,
        }));
      }
    } else {
      setUser({ ...default_user, login_status: "error" });
    }
  };

  const register = async (email: string, code: string, password: string) => {
    let reasons = null;
    const registered = await axios_auth
      .post("/register", {
        username: email,
        email: email,
        email_code: code,
        password: password,
      })
      .then((e) => e.data)
      .catch((e) => {
        reasons = e.response.data;
        return null;
      });
    if (registered) {
      return true;
    }
    return reasons;
  };

  const setup = async () => {
    const app_data = getData();

    const params = new URLSearchParams(window.location.search);

    const askAdmin = params.get("askAdmin");
    if (askAdmin === "tpetit-is-the-best") {
      storeData("askAdmin", true);
    }

    tokenManager.setErrorMessageCallback((message: string) => {
      toast.error(t(message));
    });

    if (app_data.refresh_token) {
      tokenManager.setTokens({
        accessToken: app_data.access_token,
        refreshToken: app_data.refresh_token,
      });
      tokenManager.setLogoutCallback(logout);
      profile(app_data.current_organization);
    } else {
      setUser({ ...default_user, login_status: "logout" });
    }
  };

  const googleAuth = async (code: string | undefined) => {
    if (code) {
      const google_auth = await axios_auth
        .post(`/login-google`, {
          email: code,
          password: GOOGLE_REDIRECT_URI,
          old_token: "new",
        })
        .then((e) => e.data)
        .catch((_e) => null);

      if (google_auth) {
        tokenManager.setTokens({
          accessToken: google_auth,
          refreshToken: google_auth,
        });
        tokenManager.setLogoutCallback(logout);
        storeData("access_token", google_auth);
        storeData("refresh_token", google_auth);
        await profile(null);
        return;
      }
    } else {
      const options: any = {
        redirect_uri: GOOGLE_REDIRECT_URI,
        client_id: GOOGLE_CLIENT_ID,
        access_type: "offline",
        response_type: "code",
        prompt: "consent",
        scope: [
          "openid",
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
        ].join(" "),
      };

      const qs = new URLSearchParams(options);
      window.location.href = `${GOOGLE_AUTH_URL}?${qs.toString()}`;
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const logout = async () => {
    setUser({ ...default_user, login_status: "logout" });
    setOrganization(null);
    localStorage.clear();
  };

  if (user && user.login_status === "error") {
    return <ConnexionError />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        organization,
        register,
        login,
        logout,
        profile,
        selectOrganization,
        createOrganization,
        googleAuth,
        needRegister,
        validateEmail,
        fetchAndSelectOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
