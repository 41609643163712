import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import {
  BookOpen,
  FlaskConical,
  HelpCircle,
  MessageSquareText,
} from "lucide-react";
import { Separator } from "../ui/separator";
import { Link } from "react-router-dom";

const HelpPopover = ({
  label,
  variant = "ghost",
}: {
  label: string;
  variant?: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={variant as any}>
          {label} <HelpCircle />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        className="flex flex-col items-center w-[260px] p-2"
      >
        <Button
          onClick={() => setOpen(false)}
          className="justify-start w-full"
          asChild
          variant={"ghost"}
        >
          <Link target="_blank" to="https://pro.passpass.be/docs">
            <BookOpen />
            Accéder à la documentation
          </Link>
        </Button>
        <Button
          onClick={() => setOpen(false)}
          className="justify-start w-full"
          asChild
          variant={"ghost"}
        >
          <Link target="_blank" to="https://pro.passpass.be/besoins-sur-mesure">
            <FlaskConical />
            J’ai des besoins spécifiques
          </Link>
        </Button>
        <Separator className="my-2" />
        <Button
          onClick={() => setOpen(false)}
          className="justify-start w-full"
          asChild
          variant={"ghost"}
        >
          <Link target="_blank" to="https://pro.passpass.be/contact-support/">
            <MessageSquareText />
            Contacter le support
          </Link>
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default HelpPopover;
