import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";
import "./index.css";
import "./base.scss";
import App from "./App.tsx";
import { AuthProvider } from "./store/contexts/AuthContext.tsx";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip.tsx";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga4";

if (import.meta.env.VITE_ENVIRONMENT === "prod") {
  hotjar.initialize({
    id: 5316601,
    sv: 6,
    debug: false,
  });

  ReactGA.initialize("G-TXLBWG27BY", {
    gtagOptions: {
      send_page_view: true,
    },
  });
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <Toaster richColors theme="light" />
        <TooltipProvider delayDuration={100}>
          <App />
        </TooltipProvider>
      </AuthProvider>
    </I18nextProvider>
  </StrictMode>
);
