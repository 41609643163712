import AuthContext from "@/store/contexts/AuthContext";
import { generate_image_path } from "@/utils/images";
import { useContext } from "react";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { BookType, ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const OrganizationNavbar = ({ logo }: { logo: string | null }) => {
  const { organization } = useContext(AuthContext);
  const { t } = useTranslation();

  if (!organization) return null;

  return (
    <div className="flex flex-col w-full gap-4 items-start">
      <img
        src={
          logo
            ? logo
            : generate_image_path(
                organization.organizer,
                organization.main_picture,
                "small",
                true
              )
        }
        alt=""
        className="w-[64px] h-[64px] object-cover rounded-full border"
      />
      <span>{organization.title}</span>
      <Link
        className="underline text-sm inline-flex items-center gap-2 text-muted-foreground hover:text-primary"
        to="/settings"
      >
        {t("organization.organizationSettings")}
        <ExternalLink className="h-4 w-4" />
      </Link>
      <Separator />
      <Button variant={"ghost"} className="bg-primary/10 justify-start w-full">
        <BookType />
        {t("organization.generalInformation")}
      </Button>
    </div>
  );
};
