import { useBlocker } from "react-router-dom";

const useUnsavedChanges = (
  isDirty: boolean,
  openConfirmationPopup: (nextLocation: string) => void
) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isForced = nextLocation.state?.force === true;

    if (isForced) {
      return false;
    }

    if (isDirty && currentLocation.pathname !== nextLocation.pathname) {
      openConfirmationPopup(nextLocation.pathname);
    }
    return isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  return blocker;
};

export default useUnsavedChanges;
