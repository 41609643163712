import React from "react";
import { Card } from "../ui/card";

const TitleDescriptionCard = ({
  title,
  description,
  children,
  id,
  number,
  required = false,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
  id?: string;
  number?: number;
  required?: boolean;
}) => {
  return (
    <Card className={`flex flex-col p-4`} id={id}>
      <div className="flex flex-col gap-1 mb-5">
        <div className="flex flex-row items-center gap-3 text-lg font-semibold pb-2">
          {number != undefined && (
            <div className="w-[32px] h-[32px] rounded-md bg-primary/10 flex items-center justify-center text-slate-950 text-lg font-semibold">
              {number}
            </div>
          )}
          <span>
            {title}
            {required ? <span className="text-destructive/90">*</span> : null}
          </span>
        </div>
        <p className="text-sm text-muted-foreground">{description}</p>
      </div>
      {children}
    </Card>
  );
};

export default TitleDescriptionCard;
