import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios from "@/services/axios";
import { Event } from "@/type/event";
import AuthContext from "./AuthContext";
import { refactor_dashboard_events } from "@/utils/refactor";
import ConfirmModal, {
  ConfirmFunction,
} from "@/components/common/ConfirmModal";
import { useTranslation } from "react-i18next";

type DashboardContextType = {
  events: Event[];
  updateEventStatus: (event_id: number, status: string) => Promise<boolean>;
  openConfirm: (
    title: string,
    description: string,
    confirm: ConfirmFunction,
    dismiss: ConfirmFunction,
    confirmationText: string
  ) => void;
  loadEvents: (organizer: string) => Promise<void>;
};

const initialDashboardContextValue: DashboardContextType = {
  events: [],
  updateEventStatus: async (_event_id: number, _status: string) => {
    return false;
  },
  openConfirm: (
    _title: string,
    _description: string,
    _confirm: ConfirmFunction,
    _dismiss: ConfirmFunction,
    _confirmationText: string
  ) => {},
  loadEvents: async (_organizer: string) => {},
};

const DashboardContext = createContext(initialDashboardContextValue);

type DashboardProviderProps = {
  children: ReactNode;
};

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const { organization } = useContext(AuthContext);
  const [events, setEvents] = useState<Event[]>([]);
  const { i18n } = useTranslation();
  const currentLanguage = ["en", "fr", "nl"].includes(
    i18n.language.split("-")[0]
  )
    ? i18n.language.split("-")[0]
    : "en";

  const loaded = useRef<any>({
    events: null,
  });

  const open_confirm = useRef(
    (
      _t: string,
      _d: string,
      _c: ConfirmFunction,
      _di: ConfirmFunction,
      _co: string
    ) => {}
  );

  const loadEvents = async (organizer: string) => {
    if (organization?.organizer === organizer && organization.role === "") {
      const dashboard_events = await axios
        .get(`dashboard/${organizer}/events-guest`)
        .then((e) => e.data)
        .catch((_e) => null);

      if (dashboard_events !== null) {
        setEvents(refactor_dashboard_events(dashboard_events));
      }
      return;
    }
    const dashboard_events = await axios
      .get(`dashboard/${organizer}/events`)
      .then((e) => e.data)
      .catch((_e) => null);

    if (dashboard_events !== null) {
      setEvents(refactor_dashboard_events(dashboard_events));
    }
  };

  const updateEventStatus = async (event_id: number, status: string) => {
    const response = await axios
      .put(`events/${event_id}/status/${status}`)
      .then((e) => e.data)
      .catch((_e) => null);
    if (response) {
      setEvents(
        events.map((event) => {
          if (event.id === event_id) {
            event.status = status;
          }
          return event;
        })
      );
      return true;
    }
    return false;
  };

  const openConfirm = (
    title: string,
    description: string,
    confirm: ConfirmFunction,
    dismiss: ConfirmFunction,
    confirmationText: string
  ) => {
    open_confirm.current(
      title,
      description,
      confirm,
      dismiss,
      confirmationText
    );
  };

  useEffect(() => {
    if (organization && loaded.current.events !== organization.organizer) {
      loaded.current.events = organization.organizer;
      loadEvents(organization.organizer);
    }
  }, [organization]);

  useEffect(() => {
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  return (
    <DashboardContext.Provider
      value={{
        events,
        updateEventStatus,
        openConfirm,
        loadEvents,
      }}
    >
      <ConfirmModal openConfirmRef={open_confirm} />
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
