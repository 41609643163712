import { Book, CalendarFold, Landmark, Settings } from "lucide-react";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileAsideNavbar from "./MobileAsideNavbar";
import AuthContext from "@/store/contexts/AuthContext";

const NavbarItem = ({
  href,
  icon,
}: {
  href: string;
  icon: React.ReactNode;
}) => {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <li>
      <Link
        className={`flex flex-row items-center gap-2 ${
          isActive ? "text-primary" : "text-gray-600"
        }`}
        to={href}
      >
        {React.cloneElement(icon as React.ReactElement, {
          className: `h-6 w-6 ${isActive ? "text-primary" : ""}`,
        })}
      </Link>
    </li>
  );
};

const BottomNavbar = () => {
  const { organization } = useContext(AuthContext);

  return (
    <nav
      id="bottom-navbar"
      className="flex md:hidden w-full h-[60px] flex-row items-center gap-8 justify-between bg-white border-t px-8 fixed bottom-0"
    >
      <div>
        <MobileAsideNavbar />
      </div>
      <div className="flex h-[40%] w-[1px] bg-black"></div>
      <ul className="flex flex-row items-center justify-between w-full gap-4">
        <NavbarItem href="/" icon={<CalendarFold />} />

        {organization?.role !== "event_manager" &&
          organization?.role !== "" && (
            <>
              <NavbarItem href="/organization" icon={<Book />} />
              <NavbarItem href="/finance" icon={<Landmark />} />
              <NavbarItem href="/settings" icon={<Settings />} />
            </>
          )}
      </ul>
    </nav>
  );
};

export default BottomNavbar;
