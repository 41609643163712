import EventContext from "@/store/contexts/EventContext";
import React, { useContext } from "react";
import { EventDashboardNavbar } from "./EventDashboardNavbar";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Home, RefreshCcw } from "lucide-react";
import AuthContext from "@/store/contexts/AuthContext";
import { Organization } from "@/type/user";
import EventSkeleton from "./EventSkeleton";
import { useTranslation } from "react-i18next";

const NoAccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full items-center gap-6 mt-40">
      <span className="text-2xl font-semibold text-grey-700 text-center">
        {t("events.dashboard.noAccessToEvent")}
      </span>
      <span className="text-md max-w-[600px] text-center">
        {t("events.dashboard.noAccessToEventDescription")}
      </span>
      <Button onClick={() => navigate("/")} variant="default">
        <Home className="h-4 w-4 mr-2" />
        {t("events.dashboard.returnToHome")}
      </Button>
    </div>
  );
};

const NotSameOrganization = ({
  organization,
}: {
  organization: Organization;
}) => {
  const { selectOrganization } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full items-center gap-6 mt-40">
      <span className="text-2xl font-semibold text-grey-700 text-center max-w-[700px]">
        {t("events.dashboard.noAccessToEventWithCurrentOrganization")}
      </span>
      <span className="text-md max-w-[600px] text-center">
        {t(
          "events.dashboard.noAccessToEventWithCurrentOrganizationDescription"
        )}{" "}
        {organization.title}.
      </span>
      <Button
        onClick={() => selectOrganization(organization)}
        variant="default"
      >
        <RefreshCcw className="h-4 w-4 mr-2" />
        {t("events.dashboard.changeOrganization")}
      </Button>
    </div>
  );
};

const EventDashboardContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentEvent, loaded } = useContext(EventContext);
  const { organization, user } = useContext(AuthContext);

  if (!currentEvent && loaded) return <NoAccess />;

  const eventOrganization = user.organizations.find(
    (org) => org.organizer === currentEvent?.organizer
  );

  if (
    currentEvent &&
    organization &&
    eventOrganization &&
    currentEvent.organizer !== organization?.organizer
  )
    return <NotSameOrganization organization={eventOrganization} />;

  return (
    <div className="w-full h-full md:pl-[274px]">
      <EventDashboardNavbar />
      <div className="flex w-full justify-center">
        <div className="w-full h-full p-4 max-w-[1600px]">
          {currentEvent === null ? <EventSkeleton /> : children}
        </div>
      </div>
    </div>
  );
};

export default EventDashboardContainer;
