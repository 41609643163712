import { Button } from "@/components/ui/button";
import AuthContext from "@/store/contexts/AuthContext";
import { ArrowLeftRight } from "lucide-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const FinanceNavbar = () => {
  const { organization } = useContext(AuthContext);
  const { t } = useTranslation();
  if (!organization) return null;

  return (
    <div className="flex flex-col w-full gap-4">
      <Button variant={"ghost"} className="bg-primary/10 justify-start w-full">
        <ArrowLeftRight />
        {t("finances.transactions")}
      </Button>
    </div>
  );
};
