import NavigationContent from "@/components/common/NavigationContent";
import { SettingsNavbar } from "../Settings";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import TitleDescriptionCard from "@/components/common/TitleDescriptionCard";
import { useTranslation } from "react-i18next";

const MobileApp = () => {
  const { t } = useTranslation();
  return (
    <NavigationContent
      loading={false}
      save={async () => {}}
      setData={() => {}}
      initialData={null}
      navbar={<SettingsNavbar />}
    >
      <div className="flex flex-col gap-4 max-w-[800px]">
        <TitleDescriptionCard
          title={t("organization.mobileAppTitle")}
          description={t("organization.mobileAppDescription")}
        >
          <div className="flex flex-col gap-2 items-start">
            <Button className="mt-4" variant="outline" asChild>
              <Link
                target="_blank"
                to="https://apps.apple.com/be/app/passpass-organizer/id6680189128?l=fr-FR"
              >
                {t("organization.downloadAppstore")} <ExternalLink />
              </Link>
            </Button>
            <Button variant="outline" asChild>
              <Link
                target="_blank"
                to="https://play.google.com/store/apps/details?id=com.passpass.organizer&pli=1"
              >
                {t("organization.downloadGooglePlay")} <ExternalLink />
              </Link>
            </Button>
          </div>
        </TitleDescriptionCard>
      </div>
    </NavigationContent>
  );
};

export default MobileApp;
