import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios from "@/services/axios";
import AuthContext from "./AuthContext";
import { refactor_admin_data } from "@/utils/refactor";
import { AdminEvent, AdminOrganization } from "@/type/admin";

type AdminContextType = {
  organizations: any[];
  events: any[];
  fetchData: () => Promise<void>;
};

const initialAdminContextValue: AdminContextType = {
  organizations: [],
  events: [],
  fetchData: async () => {},
};

const AdminContext = createContext(initialAdminContextValue);

type AdminProviderProps = {
  children: ReactNode;
};

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);
  const [events, setEvents] = useState<AdminEvent[]>([]);
  const loaded = useRef(false);

  const loadData = async () => {
    const data = await axios
      .get("/admin/data")
      .then((response) => response.data)
      .catch((_e) => null);
    if (data) {
      const refactored_data = refactor_admin_data(data);
      setOrganizations(refactored_data.organizations);
      setEvents(refactored_data.events);
    }
  };

  useEffect(() => {
    if (
      user &&
      user.login_status === "authenticated" &&
      user.is_admin &&
      !loaded.current
    ) {
      loadData();
      loaded.current = true;
    }
  }, [user]);

  return (
    <AdminContext.Provider
      value={{
        organizations,
        events,
        fetchData: loadData,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
