import AuthContainer from "@/components/auth/AuthContainer";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { LoaderCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import axios_auth from "@/services/axios_auth";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const FormSchema = z.object({
    password: z.string().min(1, {
      message: t("auth.messages.enter-password"),
    }),
    password_confirmation: z.string().min(1, {
      message: t("auth.messages.enter-password-confirmation"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const processReset = async (values: any) => {
    if (values.password !== values.password_confirmation) {
      toast.error(t("auth.messages.password-confirmation-mismatch"));
      return;
    }
    setLoading(true);
    const reset = await axios_auth
      .post("/reset-password", {
        email: searchParams.get("email") || "",
        password: values.password,
        email_code: searchParams.get("code") || "",
      })
      .then((e) => e.data)
      .catch((_e) => null);
    if (reset) {
      toast.success(t("auth.messages.reset-success"));
      navigate("/");
    } else {
      toast.error(t("auth.messages.reset-failed"));
    }
    setLoading(false);
  };

  return (
    <AuthContainer>
      <div className="flex flex-col w-full">
        <span className="text-lg">Réinitialiser votre mot de passe</span>
        <p className="text-base text-muted-foreground">
          Veuillez saisir votre nouveau mot de passe
        </p>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(processReset)}
            className="flex flex-col gap-6 mt-6"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Nouveau mot de passe"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password_confirmation"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Confirmez votre nouveau mot de passe"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={loading}>
              {loading && <LoaderCircle className="animate-spin" />}{" "}
              Réinitialiser mon mot de passe
            </Button>
          </form>
        </Form>
      </div>
    </AuthContainer>
  );
};

export default ResetPassword;
