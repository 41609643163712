import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import { lazy } from "react";
import ProtectedRoute from "./components/ProtectedRoute";

// Core components that are needed immediately
import Navbar from "./components/navigation/Navbar";
import BottomNavbar from "./components/navigation/BottomNavbar";
import OrganizationSelector from "./components/common/OrganizationSelector";
import { DashboardProvider } from "./store/contexts/DashboardContext";
import { EventProvider } from "./store/contexts/EventContext";
import { AdminProvider } from "./store/contexts/AdminContext";
import RoleProtectedRoute from "./components/RoleProtectedRoute";
import EventDashboardContainer from "./components/pages/event/EventDashboardContainer";
import MobileApp from "./pages/settings/MobileApp";
import EventSettings from "./pages/event/EventSettings";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/auth/ResetPassword";
import EventForm from "./pages/event/EventForm";
import EventGoHome from "./components/pages/event/EventGoHome";

let hasReloaded = false;

const UpdatingMessage = () => <span></span>;

const safeImport = (importFn: any) =>
  lazy(() =>
    importFn().catch((err: any) => {
      console.error("❌ Failed to load module:", err);

      if (!hasReloaded) {
        hasReloaded = true;
        console.log("🔄 Forcing a one-time reload...");
        window.location.reload();
      }

      return { default: UpdatingMessage };
    })
  );

// Lazy loaded auth pages
const Login = safeImport(() => import("./pages/auth/Login"));
const Register = safeImport(() => import("./pages/auth/Register"));
const ValidateEmail = safeImport(() => import("./pages/auth/ValidateEmail"));
const SetPassword = safeImport(() => import("./pages/auth/SetPassword"));
const LoginPassword = safeImport(() => import("./pages/auth/LoginPassword"));

// Lazy loaded main pages
const Home = safeImport(() => import("./pages/Home"));

// Lazy loaded event pages
const Create = safeImport(() => import("./pages/event/Create"));
const Update = safeImport(() => import("./pages/event/Update"));
const EventDashboard = safeImport(() => import("./pages/event/EventDashboard"));
const EventParticipants = safeImport(
  () => import("./pages/event/EventParticipants")
);
const EventOrders = safeImport(() => import("./pages/event/EventOrders"));
const EventEntries = safeImport(() => import("./pages/event/EventEntries"));
const EventOrder = safeImport(() => import("./pages/event/EventOrder"));
const EventParticipant = safeImport(
  () => import("./pages/event/EventParticipant")
);
const EventTicket = safeImport(() => import("./pages/event/EventTicket"));
const OrderMessage = safeImport(() => import("./pages/event/OrderMessage"));
const EventMember = safeImport(() => import("./pages/event/EventMember"));
const EventTickets = safeImport(() => import("./pages/event/EventTickets"));
const EventAddons = safeImport(() => import("./pages/event/EventAddons"));
const EventPromoCode = safeImport(() => import("./pages/event/EventPromoCode"));

// Lazy loaded other pages
const Finance = safeImport(() => import("./pages/Finance"));
const OrganizationUpdate = safeImport(() => import("./pages/Organization"));
const OrganizationMember = safeImport(
  () => import("./pages/settings/OrganizationMember")
);
const Tracking = safeImport(() => import("./pages/settings/Tracking"));

// Lazy loaded admin pages
const AdminContainer = safeImport(
  () => import("./components/admin/common/AdminContainer")
);
const Overview = safeImport(() => import("./pages/admin/Overview"));
const Organizations = safeImport(() => import("./pages/admin/Organizations"));
const Events = safeImport(() => import("./pages/admin/Events"));
const SelectOrganization = safeImport(
  () => import("./pages/admin/SelectOrganization")
);

// Layout Wrapper for Dashboard
const DashboardLayout = () => (
  <ProtectedRoute requireAuth={true}>
    <DashboardProvider>
      <Navbar />
      <OrganizationSelector />
      <div id="dashboard-content" className="pb-[60px] md:pb-0 pt-[60px]">
        <Outlet />
      </div>
      <BottomNavbar />
    </DashboardProvider>
  </ProtectedRoute>
);

// Layout Wrapper for Events
const EventLayout = () => (
  <ProtectedRoute requireAuth={true}>
    <EventProvider>
      <EventDashboardContainer>
        <Outlet />
      </EventDashboardContainer>
    </EventProvider>
  </ProtectedRoute>
);

// Layout Wrapper for Authentication Pages
const AuthLayout = () => (
  <ProtectedRoute requireAuth={false}>
    <Outlet />
  </ProtectedRoute>
);

const AdminLayout = () => (
  <AdminContainer>
    <AdminProvider>
      <Outlet />
    </AdminProvider>
  </AdminContainer>
);

// Create Router
const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "admin",
        element: <AdminLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "organizations", element: <Organizations /> },
          { path: "events", element: <Events /> },
          {
            path: "organizations/:id/:event_id",
            element: <SelectOrganization />,
          },
        ],
      },
      {
        path: "organization",
        element: (
          <RoleProtectedRoute>
            <OrganizationUpdate />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "finance",
        element: (
          <RoleProtectedRoute>
            <Finance />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: (
          <RoleProtectedRoute>
            <OrganizationMember />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "settings/tracking",
        element: (
          <RoleProtectedRoute>
            <Tracking />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "settings/mobile",
        element: (
          <RoleProtectedRoute>
            <MobileApp />
          </RoleProtectedRoute>
        ),
      },
      { path: "event/new", element: <Create /> },
      { path: "event/update/:id", element: <Update /> },
      {
        path: "event/:event_id",
        element: <EventLayout />,
        children: [
          { index: true, element: <EventDashboard /> },
          { path: "participants", element: <EventParticipants /> },
          { path: "participants/:id", element: <EventParticipant /> },
          { path: "orders", element: <EventOrders /> },
          { path: "order-messages", element: <OrderMessage /> },
          { path: "orders/:id", element: <EventOrder /> },
          { path: "order-fields", element: <EventForm /> },
          { path: "entries", element: <EventEntries /> },
          { path: "tickets", element: <EventTickets /> },
          { path: "addons", element: <EventAddons /> },
          { path: "promos", element: <EventPromoCode /> },
          { path: "tickets/:id", element: <EventTicket /> },
          { path: "team", element: <EventMember /> },
          { path: "settings", element: <EventSettings /> },
          { path: "*", element: <EventGoHome /> },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "login-password", element: <LoginPassword /> },
      { path: "register", element: <Register /> },
      { path: "register/validate-email", element: <ValidateEmail /> },
      { path: "register/password", element: <SetPassword /> },
      { path: "reset-password", element: <ResetPassword /> },
    ],
  },
  { path: "*", element: <Navigate to="/" replace /> },
]);

// Main App Component
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
