import { Check, Globe } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const languages = [
  { code: "en", name: "English" },
  { code: "fr", name: "Français" },
  // { code: "nl", name: "Nederlands" },
  // { code: "aa", name: "Only aaa" },
] as const;

type LanguageCode = (typeof languages)[number]["code"];

const LanguageSelector = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const currentLanguage = ["en", "fr", "nl", "aa"].includes(
    i18n.language.split("-")[0]
  )
    ? i18n.language.split("-")[0]
    : "en";

  useEffect(() => {
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  const setLanguage = (code: LanguageCode) => {
    i18n.changeLanguage(code);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          className={fullWidth ? "w-full justify-between" : ""}
          variant="ghost"
        >
          {languages.find((l) => l.code === currentLanguage)?.name}
          <Globe className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-1">
        {languages.map((language) => (
          <Button
            key={language.code}
            variant="ghost"
            className={cn(
              "w-full justify-start gap-2",
              currentLanguage === language.code && "bg-accent"
            )}
            onClick={() => setLanguage(language.code)}
          >
            <span>{language.name}</span>
            {currentLanguage === language.code && (
              <Check className="h-4 w-4 ml-auto" />
            )}
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default LanguageSelector;
