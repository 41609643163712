import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import AuthContext from "@/store/contexts/AuthContext";
import { generate_image_path } from "@/utils/images";
import {
  ExternalLink,
  Link as LinkIcon,
  Smartphone,
  Users,
} from "lucide-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const SettingsNavbar = () => {
  const { organization } = useContext(AuthContext);

  const { t } = useTranslation();
  const location = useLocation();

  if (!organization) return null;

  return (
    <div className="flex flex-col w-full gap-4 items-start">
      <img
        src={generate_image_path(
          organization.organizer,
          organization.main_picture,
          "small",
          true
        )}
        alt=""
        className="w-[64px] h-[64px] object-cover rounded-full border"
      />
      <span>{organization.title}</span>
      <Link
        className="underline text-sm inline-flex items-center gap-2 text-muted-foreground hover:text-primary"
        to="/organization"
      >
        {t("organization.organizationPage")}
        <ExternalLink className="h-4 w-4" />
      </Link>
      <Separator />
      <Button
        variant={"ghost"}
        className={`justify-start w-full ${
          location.pathname === "/settings" ? "bg-primary/10" : ""
        }`}
        asChild
      >
        <Link to="/settings">
          <Users className="w-4 h-4" />
          {t("organization.organziationMembers")}
        </Link>
      </Button>
      <Button
        variant={"ghost"}
        className={`justify-start w-full ${
          location.pathname === "/settings/tracking" ? "bg-primary/10" : ""
        }`}
        asChild
      >
        <Link to="/settings/tracking">
          <LinkIcon className="w-4 h-4" />
          {t("organization.trackingWebhook")}
        </Link>
      </Button>
      <Button
        variant={"ghost"}
        className={`justify-start w-full ${
          location.pathname === "/settings/mobile" ? "bg-primary/10" : ""
        }`}
        asChild
      >
        <Link to="/settings/mobile">
          <Smartphone className="w-4 h-4" />
          {t("organization.mobileApp")}
        </Link>
      </Button>
    </div>
  );
};
