export const storeData = (key: string, value: any) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
};

export const getSpecificData = (key: string) => {
  const jsonValue = localStorage.getItem(key);
  return jsonValue !== null ? JSON.parse(jsonValue) : null;
};

export const getData = () => {
  let get_dict: any = {
    access_token: null,
    refresh_token: null,
    user: null,
    current_organization: null,
    askAdmin: false,
  };
  for (var key in get_dict) {
    const jsonValue = localStorage.getItem(key);
    try {
      get_dict[key] = jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch {
      get_dict[key] = null;
    }
  }
  return get_dict;
};
