"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const SelectWithSearch = ({
  value,
  options,
  placeholder = "Select framework...",
  noFoundPlaceholder = "No framework found...",
  onChange,
  className = "",
}: {
  value: any;
  options: { label: any; value: any; id: any }[];
  placeholder?: string;
  noFoundPlaceholder?: string;
  onChange: (value: any) => void;
  className?: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(value);

  const updatedOptions = options.map((option) => {
    if (options.filter((o) => o.label === option.label).length > 1) {
      return {
        ...option,
        label: `${option.label} (${option.id})`,
      };
    }
    return option;
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between bg-slate-50 md:bg-transparent ${className}`}
        >
          <span className="truncate max-w-[140px]">
            {currentValue
              ? updatedOptions.find((option) => option.value === currentValue)
                  ?.label
              : placeholder}
          </span>
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder={placeholder} className="h-9" />
          <CommandList>
            <CommandEmpty>{noFoundPlaceholder}</CommandEmpty>
            <CommandGroup>
              {updatedOptions.map((option) => (
                <CommandItem
                  className="w-[220px]"
                  key={option.value}
                  value={option.value}
                  onSelect={() => {
                    setCurrentValue(option.value);
                    onChange(option.value);
                    setOpen(false);
                  }}
                >
                  {option.label}
                  <Check
                    className={cn(
                      "ml-auto",
                      currentValue === option.value
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SelectWithSearch;
