import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import { useLocation } from "react-router-dom";
import { EventDashboardNavbarContent } from "../pages/event/EventDashboardNavbar";
import DashboardContext from "@/store/contexts/DashboardContext";
import { useContext, useEffect, useState } from "react";
import { SettingsNavbar } from "@/pages/Settings";
import { FinanceNavbar } from "../pages/finance/FinanceNavbar";
import { OrganizationNavbar } from "../pages/organization/OrganizationNavbar";

const MobileAsideContent = () => {
  const location = useLocation();
  const { events } = useContext(DashboardContext);

  if (
    location.pathname === "/event/new" ||
    location.pathname.startsWith("/event/update")
  ) {
    return null;
  } else if (location.pathname.startsWith("/event/")) {
    const currentEvent = events.find(
      (e) => e.id === parseInt(location.pathname.split("/")[2])
    );

    return <EventDashboardNavbarContent currentEvent={currentEvent ?? null} />;
  } else if (location.pathname === "/organization") {
    return <OrganizationNavbar logo={null} />;
  } else if (location.pathname === "/settings") {
    return <SettingsNavbar />;
  } else if (location.pathname === "/finance") {
    return <FinanceNavbar />;
  }
  return null;
};

const MobileAsideNavbar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger onClick={() => setOpen(true)} asChild>
        <Menu />
      </SheetTrigger>

      <SheetContent
        className="data-[state=closed]:duration-200 data-[state=open]:duration-300 bg-white px-0"
        side="left"
      >
        <SheetHeader>
          <SheetTitle></SheetTitle>
        </SheetHeader>
        <div className="pt-[40px]">
          <MobileAsideContent />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MobileAsideNavbar;
