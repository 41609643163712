import { Event } from "@/type/event";
import { formatEventTitle } from "./event";
import { toast } from "sonner";

export function generateRandomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  return randomString;
}

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  // Convert to 32bit unsigned integer in base 36 and pad with "0" to ensure length is 7.
  return (hash >>> 0).toString(36);
};

export const getSearchFilter = (
  searchParams: URLSearchParams,
  allowed: string[],
  defaultValue: string
) => {
  const filter = searchParams.get("filter");
  if (!filter) return defaultValue;
  if (filter === "all") return "all";
  if (allowed.includes(filter)) return filter;
  return defaultValue;
};

export const trimText = (text: string, length: number = 100) => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};

export const darkenHexColor = (hexColor: string, factor = 0.5) => {
  // Remove the "#" symbol if present
  hexColor = hexColor.replace(/^#/, "");

  // Convert the hex color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Darken the color by reducing the brightness
  const darkR = Math.max(0, Math.floor(r * factor));
  const darkG = Math.max(0, Math.floor(g * factor));
  const darkB = Math.max(0, Math.floor(b * factor));

  // Convert the darkened RGB values back to hex
  const darkHexColor = `#${darkR.toString(16).padStart(2, "0")}${darkG
    .toString(16)
    .padStart(2, "0")}${darkB.toString(16).padStart(2, "0")}`;

  return darkHexColor;
};

export const getTextColorForBackground = (hexColor: string) => {
  if (hexColor.length == 4) {
    hexColor = `${hexColor}${hexColor.substring(1)}`;
  }

  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance < 140 ? "white" : "black";
};

export const hexToRGBA = (hex: string, opacity: number) => {
  // Remove the "#" if present
  hex = hex.replace(/^#/, "");

  // If shorthand hex (#RGB), convert to full form (#RRGGBB)
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((x) => x + x)
      .join("");
  }

  // Convert to integer values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const trimChar = (str: string, char: string) => {
  let start = 0,
    end = str.length - 1;

  while (str[start] === char) start++;
  while (str[end] === char) end--;

  return str.slice(start, end + 1);
};

export const capitalizeWord = (word: string) => {
  if (!word) return ""; // Handle empty string case
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const generateEventLink = (event: Event) => {
  if (event.status !== "published")
    return `${import.meta.env.VITE_SITE_URL}/events/preview/${
      event.id
    }/${formatEventTitle(event.title)}/${event.access_token}`;
  const base_link = `${import.meta.env.VITE_SITE_URL}/events/${
    event.id
  }/${formatEventTitle(event.title)}`;
  return `${base_link}${
    event.visibility === "private"
      ? `?t=${simpleHash(event.id.toString())}`
      : ""
  }`;
};

export const previewEvent = (event: Event) => {
  window.open(generateEventLink(event));
};

export const copyEventLink = (event: Event) => {
  if (event.status !== "published") {
    toast.error("Vous devez publier l'événement avant de pouvoir le partager.");
  } else {
    try {
      navigator.clipboard.writeText(generateEventLink(event));
      toast.success("Lien copié");
    } catch (error) {
      toast.error("Failed to copy link to clipboard");
    }
  }
};

export const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
