import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import useUnsavedChanges from "@/hooks/use-unsaved-changes";
import DashboardContext from "@/store/contexts/DashboardContext";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { LoaderCircle } from "lucide-react";

const EventSave = ({
  children,
  initialData,
  setData,
  save,
  loading,
  reset = 0,
}: {
  children: React.ReactNode;
  initialData: any;
  setData: (data: any) => void;
  save: () => Promise<any>;
  loading: boolean;
  reset?: number;
}) => {
  const { openConfirm } = useContext(DashboardContext);
  const initial = useRef(initialData);
  const hasChanges =
    JSON.stringify(initial.current) !== JSON.stringify(initialData);
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);

  const handleLeave = (nextLocation: string) => {
    openConfirm(
      "Quitter sans sauvegarder ?",
      "Vous avez des modifications non enregistrées. Si vous quittez maintenant, vos changements seront perdus.",
      {
        label: "Sauvegarder et quitter",
        onClick: async () => {
          await save();
          navigate(nextLocation, { state: { force: true } });
        },
        variant: "default",
      },
      {
        label: "Quitter",
        onClick: () => {
          navigate(nextLocation, { state: { force: true } });
        },
        variant: "outline",
      },
      ""
    );
  };

  useUnsavedChanges(
    hasChanges,
    loading
      ? () => {
          toast.error("Sauvegarde en cours");
        }
      : handleLeave
  );

  useEffect(() => {
    initial.current = initialData;
    setReload(!reload);
  }, [reset]);

  return (
    <div className="w-full">
      <div className="flex w-full justify-center">
        <div className="w-full w-full max-w-[1400px] pb-[80px]">{children}</div>
      </div>
      <div
        className={`flex items-center justify-end transition-all duration-300 fixed ${
          hasChanges ? "bottom-[60px] md:bottom-0" : "-bottom-[60px]"
        } right-0 gap-4 w-full pl-[270px] px-4 h-[60px] bg-white border-t `}
      >
        <Button
          disabled={loading}
          variant={"outline"}
          onClick={() => {
            setData(initial.current);
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={async () => {
            await save();
            initial.current = initialData;
          }}
          disabled={loading}
        >
          {loading && <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />}
          Sauvegarder
        </Button>
      </div>
    </div>
  );
};

export default EventSave;
