import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { EventFull } from "@/type/event";
import { useParams } from "react-router-dom";
import axios from "@/services/axios";
import AuthContext from "./AuthContext";
import { entries_refactor, refactor_event_full } from "@/utils/refactor";

type EventContextType = {
  currentEvent: EventFull | null;
  setCurrentEvent: (event: EventFull | null) => void;
  loaded: boolean;
  loadEntries: ({ event_id }: { event_id: number }) => void;
  fetchEvent: () => Promise<void>;
};

const initialEventContextValue: EventContextType = {
  currentEvent: null,
  setCurrentEvent: () => {},
  loaded: false,
  loadEntries: () => {},
  fetchEvent: async () => {},
};

const EventContext = createContext(initialEventContextValue);

type EventProviderProps = {
  children: ReactNode;
};

export const EventProvider: React.FC<EventProviderProps> = ({ children }) => {
  const [currentEvent, setCurrentEvent] = useState<EventFull | null>(null);
  const [loaded, setLoaded] = useState(false);
  const loadedRef = useRef({
    entries: 0,
  });
  const { organization } = useContext(AuthContext);
  let { event_id } = useParams();

  const loadEntries = async ({ event_id }: { event_id: number }) => {
    if (loadedRef.current.entries === event_id) return;

    loadedRef.current.entries = event_id;
    const entries = await axios
      .get(`/events/${event_id}/entries`)
      .then((e) => e.data)
      .catch((_e) => null);

    if (entries !== null) {
      setCurrentEvent({
        ...currentEvent,
        entries: entries_refactor(entries, currentEvent?.participants || []),
      } as EventFull);
    } else {
      loadedRef.current.entries = 0;
    }
  };

  const fetchEvent = async () => {
    const event_response = await axios
      .get(`/dashboard/${organization?.organizer}/events/${event_id}`)
      .then((e) => e.data)
      .catch((_e) => null);
    const event = await axios
      .get(`/events/${event_id}`)
      .then((e) => e.data)
      .catch((_e) => null);

    if (event_response !== null && event !== null) {
      const newEvent = refactor_event_full({
        ...event_response,
        event_capacity: event.event_capacity,
      });
      setCurrentEvent({
        ...newEvent,
        confirmation_page_message: event.confirmation_page_message,
        email_pdf_message: event.email_pdf_message,
        meta_pixel_id: event.meta_pixel_id,
        event_capacity: event.event_capacity,
      });
    }
    setLoaded(true);
  };

  useEffect(() => {
    if (event_id && organization) {
      fetchEvent();
    }
  }, [event_id, organization]);

  return (
    <EventContext.Provider
      value={{
        loaded,
        currentEvent,
        setCurrentEvent,
        loadEntries,
        fetchEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
