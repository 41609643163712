import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { WifiOff } from "lucide-react";

const ConnexionError = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center p-4">
      <Card className="w-full max-w-md text-center">
        <CardHeader>
          <div className="flex justify-center mb-4">
            <WifiOff className="h-12 w-12 text-destructive" />
          </div>
          <CardTitle className="text-2xl">Connection Error</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">
            We're unable to connect to our servers. This might be due to a
            network issue or server maintenance.
          </p>
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button
            variant="default"
            onClick={() => window.location.reload()}
            className="min-w-[120px]"
          >
            Retry Connection
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ConnexionError;
