import TitleDescriptionCard from "@/components/common/TitleDescriptionCard";
import EventSave from "@/components/pages/event/EventSave";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import EventContext from "@/store/contexts/EventContext";
import { EventFull } from "@/type/event";
import { saveEventPart } from "@/utils/event";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";

const EventSettings = () => {
  const { currentEvent, setCurrentEvent } = useContext(EventContext);
  const [loading, setLoading] = useState(false);
  const [settingsForm, setSettingsForm] = useState<any>({
    meta_pixel_id: currentEvent?.meta_pixel_id ?? "",
  });

  // Validate Meta Pixel ID format (should be a numeric string)
  const isValidMetaPixelId = (id: string): boolean => {
    // Allow empty string (to remove the pixel ID)
    if (id === "") return true;

    // Meta Pixel IDs are typically numeric strings
    return /^\d+$/.test(id);
  };

  const handleSave = async () => {
    // Validate Meta Pixel ID format before saving
    if (!isValidMetaPixelId(settingsForm.meta_pixel_id)) {
      toast.error(
        "Format de Meta Pixel ID invalide. Veuillez entrer un ID numérique valide."
      );
      return;
    }

    setLoading(true);
    const updated = await saveEventPart(currentEvent?.id ?? 0, {
      meta_pixel_id: settingsForm.meta_pixel_id,
    });
    if (updated) {
      setCurrentEvent({
        ...currentEvent,
        meta_pixel_id: updated.meta_pixel_id,
      } as EventFull);
    }
    setLoading(false);
  };

  return (
    <EventSave
      loading={loading}
      save={handleSave}
      initialData={settingsForm}
      setData={setSettingsForm}
    >
      <div className="flex flex-col gap-4 w-full max-w-[800px]">
        <h1 className="text-3xl font-semibold font-anek">
          Configurations avancées
        </h1>
        <TitleDescriptionCard
          title={"Meta Pixel ID"}
          description={
            "Entrez ici votre Meta Pixel ID pour analyser vos données."
          }
        >
          <Input
            value={settingsForm?.meta_pixel_id}
            onChange={(e) =>
              setSettingsForm({
                ...settingsForm,
                meta_pixel_id: e.target.value,
              })
            }
            type="text"
            placeholder="Meta Pixel ID"
          />
        </TitleDescriptionCard>
        <TitleDescriptionCard
          title={"Webhook"}
          description={
            "Activer les webhook pour recevoir des notifications sur votre application."
          }
        >
          <Button variant="outline" className="w-max" asChild>
            <Link to="/settings/tracking">
              Gestion des webhooks depuis les paramètres de l'organisation
            </Link>
          </Button>
        </TitleDescriptionCard>
      </div>
    </EventSave>
  );
};

export default EventSettings;
